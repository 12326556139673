"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function ChartSquareIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 20 21",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M8.42394 15.4647H5.26562C4.92396 15.4647 4.64062 15.1814 4.64062 14.8397V10.5731C4.64062 9.70639 5.34895 8.99805 6.21561 8.99805H8.42394C8.76561 8.99805 9.04894 9.28138 9.04894 9.62305V14.8314C9.04894 15.1814 8.76561 15.4647 8.42394 15.4647ZM5.89062 14.2147H7.79894V10.2564H6.21561C6.04061 10.2564 5.89062 10.398 5.89062 10.5813V14.2147Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M11.5744 15.4649H8.41602C8.07435 15.4649 7.79102 15.1816 7.79102 14.8399V6.78986C7.79102 5.92319 8.49934 5.21484 9.366 5.21484H10.6327C11.4994 5.21484 12.2077 5.92319 12.2077 6.78986V14.8399C12.1993 15.1816 11.9244 15.4649 11.5744 15.4649ZM9.04933 14.2149H10.9577V6.78986C10.9577 6.61486 10.816 6.46484 10.6327 6.46484H9.366C9.191 6.46484 9.04102 6.60652 9.04102 6.78986V14.2149H9.04933Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M14.7325 15.4646H11.5742C11.2326 15.4646 10.9492 15.1812 10.9492 14.8396V11.0479C10.9492 10.7062 11.2326 10.4229 11.5742 10.4229H13.7825C14.6492 10.4229 15.3575 11.1312 15.3575 11.9979V14.8396C15.3575 15.1812 15.0825 15.4646 14.7325 15.4646ZM12.1992 14.2146H14.1075V11.9979C14.1075 11.8229 13.9659 11.6729 13.7825 11.6729H12.1992V14.2146Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M12.4993 19.2985H7.49935C2.97435 19.2985 1.04102 17.3652 1.04102 12.8402V7.84017C1.04102 3.31517 2.97435 1.38184 7.49935 1.38184H12.4993C17.0243 1.38184 18.9577 3.31517 18.9577 7.84017V12.8402C18.9577 17.3652 17.0243 19.2985 12.4993 19.2985ZM7.49935 2.63184C3.65768 2.63184 2.29102 3.9985 2.29102 7.84017V12.8402C2.29102 16.6818 3.65768 18.0485 7.49935 18.0485H12.4993C16.341 18.0485 17.7077 16.6818 17.7077 12.8402V7.84017C17.7077 3.9985 16.341 2.63184 12.4993 2.63184H7.49935Z"
  }));
}


exports.default = ChartSquareIcon;
