"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _colors = require('../colors');
var _constants = require('../constants');
const buildAccordionVariant = (mode) => ({
  MuiAccordion: {
    defaultProps: {
      elevation: 0,
      disableGutters: true
    },
    styleOverrides: {
      root: {
        padding: _constants.SPACING.call(void 0, 6),
        borderRadius: `${_constants.SPACING.call(void 0, 4)} !important`,
        background: _colors.colors[mode].background.quartery,
        "&:last-child": {
          borderBottom: "none"
        },
        "&:before": {
          opacity: 0
        }
      }
    }
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        padding: 0,
        "& .MuiSvgIcon-root": {
          color: _colors.colors[mode].typography.typo2
        },
        minHeight: 0
      },
      content: {
        margin: 0
      }
    }
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        padding: `${_constants.SPACING.call(void 0, 6)} 0 0`,
        marginTop: 0,
        display: "flex",
        flexDirection: "column",
        gap: _constants.SPACING.call(void 0, 6)
      }
    }
  }
});


exports.buildAccordionVariant = buildAccordionVariant;
