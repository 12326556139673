"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function GlobalIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 20 20",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M9.99935 18.9596C5.05768 18.9596 1.04102 14.943 1.04102 10.0013C1.04102 5.05964 5.05768 1.04297 9.99935 1.04297C14.941 1.04297 18.9577 5.05964 18.9577 10.0013C18.9577 14.943 14.941 18.9596 9.99935 18.9596ZM9.99935 2.29297C5.74935 2.29297 2.29102 5.7513 2.29102 10.0013C2.29102 14.2513 5.74935 17.7096 9.99935 17.7096C14.2493 17.7096 17.7077 14.2513 17.7077 10.0013C17.7077 5.7513 14.2493 2.29297 9.99935 2.29297Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M7.49922 18.125H6.66589C6.32422 18.125 6.04089 17.8417 6.04089 17.5C6.04089 17.1583 6.30755 16.8833 6.64922 16.875C5.34089 12.4083 5.34089 7.59167 6.64922 3.125C6.30755 3.11667 6.04089 2.84167 6.04089 2.5C6.04089 2.15833 6.32422 1.875 6.66589 1.875H7.49922C7.69922 1.875 7.89089 1.975 8.00755 2.13333C8.12422 2.3 8.15755 2.50833 8.09089 2.7C6.52422 7.40833 6.52422 12.5917 8.09089 17.3083C8.15755 17.5 8.12422 17.7083 8.00755 17.875C7.89089 18.025 7.69922 18.125 7.49922 18.125Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M12.5 18.1243C12.4333 18.1243 12.3667 18.1159 12.3 18.0909C11.975 17.9826 11.7917 17.6243 11.9083 17.2993C13.475 12.5909 13.475 7.40759 11.9083 2.69092C11.8 2.36592 11.975 2.00759 12.3 1.89925C12.6333 1.79092 12.9833 1.96592 13.0917 2.29092C14.75 7.25759 14.75 12.7243 13.0917 17.6826C13.0083 17.9576 12.7583 18.1243 12.5 18.1243Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M10 14.334C7.675 14.334 5.35833 14.009 3.125 13.3506C3.11667 13.684 2.84167 13.959 2.5 13.959C2.15833 13.959 1.875 13.6756 1.875 13.334V12.5006C1.875 12.3006 1.975 12.109 2.13333 11.9923C2.3 11.8756 2.50833 11.8423 2.7 11.909C7.40833 13.4756 12.6 13.4756 17.3083 11.909C17.5 11.8423 17.7083 11.8756 17.875 11.9923C18.0417 12.109 18.1333 12.3006 18.1333 12.5006V13.334C18.1333 13.6756 17.85 13.959 17.5083 13.959C17.1667 13.959 16.8917 13.6923 16.8833 13.3506C14.6417 14.009 12.325 14.334 10 14.334Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M17.4992 8.12448C17.4325 8.12448 17.3658 8.11615 17.2992 8.09115C12.5908 6.52448 7.39917 6.52448 2.69084 8.09115C2.3575 8.19948 2.0075 8.02448 1.89917 7.69948C1.79917 7.36615 1.97417 7.01615 2.29917 6.90781C7.26584 5.24948 12.7325 5.24948 17.6908 6.90781C18.0158 7.01615 18.1992 7.37448 18.0825 7.69948C18.0075 7.95781 17.7575 8.12448 17.4992 8.12448Z"
  }));
}


exports.default = GlobalIcon;
