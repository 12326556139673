"use strict";Object.defineProperty(exports, "__esModule", {value: true});var PositionVersions = /* @__PURE__ */ ((PositionVersions2) => {
  PositionVersions2["POSITION_VERSION_1"] = "1";
  PositionVersions2["POSITION_VERSION_2"] = "2";
  PositionVersions2["POSITION_VERSION_3"] = "3";
  PositionVersions2["POSITION_VERSION_4"] = "4";
  return PositionVersions2;
})(PositionVersions || {});


exports.PositionVersions = PositionVersions;
