"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
var _styledcomponents = require('styled-components');
var _theme = require('../theme');
function WarningTriangleIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  const {
    palette: {
      mode
    }
  } = _styledcomponents.useTheme.call(void 0, );
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 24 24",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M21.5259 18.5L12.8657 3.5C12.4808 2.83333 11.5185 2.83333 11.1336 3.5L2.47336 18.5C2.08846 19.1667 2.56958 20 3.33938 20H20.6599C21.4297 20 21.9108 19.1667 21.5259 18.5Z",
    stroke: _theme.colors[mode].semantic.error.darker,
    strokeWidth: "1.6",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    fill: "none"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16Z",
    fill: _theme.colors[mode].semantic.error.darker
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M12 13L12 10",
    stroke: _theme.colors[mode].semantic.error.darker,
    strokeWidth: "1.6",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }));
}


exports.default = WarningTriangleIcon;
