"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function LightingIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 18 23",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M6.98999 22.25C6.78999 22.25 6.62999 22.21 6.50999 22.16C6.10999 22.01 5.42999 21.52 5.42999 19.97V13.52H3.08999C1.74999 13.52 1.26999 12.89 1.09999 12.52C0.929986 12.14 0.779986 11.37 1.65999 10.36L9.22999 1.76001C10.25 0.600006 11.08 0.680006 11.48 0.830006C11.88 0.980006 12.56 1.47001 12.56 3.02001V9.47001H14.9C16.24 9.47001 16.72 10.1 16.89 10.47C17.06 10.85 17.21 11.62 16.33 12.63L8.75999 21.23C8.04999 22.04 7.42999 22.25 6.98999 22.25ZM10.93 2.24001C10.9 2.28001 10.69 2.38001 10.36 2.76001L2.78999 11.36C2.50999 11.68 2.46999 11.88 2.46999 11.92C2.48999 11.93 2.66999 12.03 3.08999 12.03H6.17999C6.58999 12.03 6.92999 12.37 6.92999 12.78V19.98C6.92999 20.48 7.01999 20.7 7.05999 20.76C7.08999 20.72 7.29999 20.62 7.62999 20.24L15.2 11.64C15.48 11.32 15.52 11.12 15.52 11.08C15.5 11.07 15.32 10.97 14.9 10.97H11.81C11.4 10.97 11.06 10.63 11.06 10.22V3.02001C11.07 2.52001 10.97 2.31001 10.93 2.24001Z"
  }));
}


exports.default = LightingIcon;
