"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function LangIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 25 25",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M14.79 19.92C14.68 19.92 14.56 19.89 14.46 19.84C14.09 19.65 13.94 19.2 14.12 18.83L16.26 14.56C16.39 14.31 16.65 14.15 16.93 14.15C17.21 14.15 17.47 14.31 17.6 14.57L19.74 18.84C19.93 19.21 19.78 19.66 19.4 19.85C19.03 20.04 18.58 19.89 18.39 19.51L16.93 16.58L15.47 19.51C15.33 19.77 15.06 19.92 14.79 19.92Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M18.6801 19.1599H15.1602C14.7502 19.1599 14.4102 18.8199 14.4102 18.4099C14.4102 17.9999 14.7502 17.6599 15.1602 17.6599H18.6801C19.0901 17.6599 19.4301 17.9999 19.4301 18.4099C19.4301 18.8199 19.1001 19.1599 18.6801 19.1599Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M16.9198 23.2501C13.7098 23.2501 11.0898 20.64 11.0898 17.42C11.0898 14.2 13.6998 11.5901 16.9198 11.5901C20.1298 11.5901 22.7498 14.2 22.7498 17.42C22.7498 20.64 20.1398 23.2501 16.9198 23.2501ZM16.9198 13.1C14.5298 13.1 12.5898 15.0401 12.5898 17.4301C12.5898 19.8201 14.5298 21.7601 16.9198 21.7601C19.2998 21.7601 21.2498 19.8201 21.2498 17.4301C21.2498 15.0401 19.3098 13.1 16.9198 13.1Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M4.86 13.2C3.75 13.2 2.87 12.89 2.25 12.29C1.59 11.65 1.25 10.68 1.25 9.42999V5.51001C1.25 3.08001 2.59 1.73999 5.02 1.73999H8.94C10.19 1.73999 11.15 2.06999 11.8 2.73999C12.43 3.38999 12.74 4.33003 12.71 5.53003V9.42999C12.74 10.65 12.43 11.61 11.78 12.26C11.13 12.91 10.17 13.21 8.92999 13.19H5.03C4.96 13.2 4.91 13.2 4.86 13.2ZM5.02 3.25C3.41 3.25 2.75 3.91002 2.75 5.52002V9.44C2.75 10.27 2.92999 10.87 3.28999 11.22C3.63999 11.56 4.20001 11.71 4.99001 11.7H8.92999C9.76999 11.72 10.35 11.55 10.7 11.2C11.05 10.85 11.21 10.26 11.19 9.45001V5.51001C11.21 4.71001 11.05 4.13998 10.71 3.78998C10.36 3.42998 9.75999 3.25 8.92999 3.25H5.02Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M8.99994 7.09985H4.93994C4.52994 7.09985 4.18994 6.75985 4.18994 6.34985C4.18994 5.93985 4.52994 5.59985 4.93994 5.59985H8.99994C9.40994 5.59985 9.74994 5.93985 9.74994 6.34985C9.74994 6.75985 9.41994 7.09985 8.99994 7.09985Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M6.97998 7.09991C6.56998 7.09991 6.22998 6.75991 6.22998 6.34991V5.66992C6.22998 5.25992 6.56998 4.91992 6.97998 4.91992C7.38998 4.91992 7.72998 5.25992 7.72998 5.66992V6.34991C7.72998 6.75991 7.38998 7.09991 6.97998 7.09991Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M4.93994 10.2599C4.52994 10.2599 4.18994 9.91995 4.18994 9.50995C4.18994 9.09995 4.52994 8.75995 4.93994 8.75995C6.20994 8.75995 7.23994 7.67997 7.23994 6.33997C7.23994 5.92997 7.57994 5.58997 7.98994 5.58997C8.39994 5.58997 8.73994 5.92997 8.73994 6.33997C8.73994 8.49997 7.03994 10.2599 4.93994 10.2599Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M9.00993 10.2599C8.07993 10.2599 7.18994 9.78996 6.55994 8.95996C6.30994 8.62996 6.37995 8.15991 6.70995 7.90991C7.03995 7.65991 7.50993 7.72993 7.75993 8.05993C8.09993 8.51993 8.54993 8.76995 9.00993 8.76995C9.41993 8.76995 9.75993 9.10995 9.75993 9.51995C9.75993 9.92995 9.41993 10.2599 9.00993 10.2599Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M9 23.25C4.73 23.25 1.25 19.77 1.25 15.5C1.25 15.09 1.59 14.75 2 14.75C2.41 14.75 2.75 15.09 2.75 15.5C2.75 18.46 4.81 20.94 7.58 21.59L7.31 21.14C7.1 20.78 7.21001 20.32 7.57001 20.11C7.92001 19.9 8.39001 20.01 8.60001 20.37L9.64999 22.12C9.78999 22.35 9.79 22.64 9.66 22.87C9.52 23.1 9.27 23.25 9 23.25Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M21.9999 10.25C21.5899 10.25 21.2499 9.91 21.2499 9.5C21.2499 6.54 19.1899 4.05997 16.4199 3.40997L16.6899 3.85999C16.8999 4.21999 16.7899 4.68001 16.4299 4.89001C16.0799 5.10001 15.6099 4.99001 15.3999 4.63L14.3499 2.88C14.2099 2.65 14.2099 2.36 14.3399 2.13C14.4699 1.89 14.7199 1.75 14.9899 1.75C19.2599 1.75 22.7399 5.23 22.7399 9.5C22.7499 9.91 22.4099 10.25 21.9999 10.25Z"
  }));
}


exports.default = LangIcon;
