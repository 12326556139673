"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _AccordionSummary = require('@mui/material/AccordionSummary'); var _AccordionSummary2 = _interopRequireDefault(_AccordionSummary);
var _icons = require('../../icons');
const AccordionSummary = (props) => /* @__PURE__ */ _react2.default.createElement(_AccordionSummary2.default, _extends({
  expandIcon: /* @__PURE__ */ _react2.default.createElement(_icons.ExpandMoreIcon, {
    fontSize: "medium"
  })
}, props));


exports.AccordionSummary = AccordionSummary;
