"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _colors = require('../colors');
const buildSvgIconVariant = (mode) => ({
  MuiSvgIcon: {
    styleOverrides: {
      colorDisabled: {
        color: _colors.colors[mode].typography.typo5
      },
      root: {
        "&.MuiSvgIcon-colorSuccess": {
          color: _colors.colors[mode].semantic.success.darker
        },
        "&.MuiSvgIcon-colorWarning": {
          color: _colors.colors[mode].semantic.warning.darker
        },
        "&.MuiSvgIcon-colorError": {
          color: _colors.colors[mode].semantic.error.darker
        }
      },
      fontSizeSmall: {
        fontSize: "1.125rem !important"
        // 18/16
      },
      fontSizeMedium: {
        fontSize: "1.25rem"
        // 20/16
      },
      fontSizeLarge: {
        fontSize: "1.5rem !important"
        // 24/16
      }
    }
  }
});


exports.buildSvgIconVariant = buildSvgIconVariant;
