"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _styledcomponents = require('styled-components');
var _theme = require('../theme');
const DonutShapeUrl = 'url("https://ipfs.io/ipfs/QmZ8VeLhm96WUzqmRfH94SkMKJcoyT9ZPWCn9731xzTG7i")';
function DonutShape(_ref) {
  let {
    persistThemeColor,
    width = "146px",
    height = "146px",
    ...style
  } = _ref;
  const {
    palette: {
      mode
    }
  } = _styledcomponents.useTheme.call(void 0, );
  return /* @__PURE__ */ _react2.default.createElement("div", {
    style: {
      ...style,
      position: "relative"
    }
  }, /* @__PURE__ */ _react2.default.createElement("div", {
    style: {
      background: `${DonutShapeUrl} 50% / contain no-repeat`,
      width,
      height,
      position: "absolute"
    }
  }), /* @__PURE__ */ _react2.default.createElement("div", {
    style: {
      backgroundColor: _theme.colors[persistThemeColor || mode].donutShape.mask,
      mask: `${DonutShapeUrl} center / contain no-repeat`,
      maskPosition: "center",
      width,
      height,
      mixBlendMode: "color"
    }
  }));
}


exports.default = DonutShape;
