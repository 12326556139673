"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function ArrowSwapIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 21 20",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    id: "Vector",
    d: "M11.5345 14.3983C11.5345 14.24 11.5928 14.0816 11.7178 13.9566C11.9595 13.715 12.3595 13.715 12.6012 13.9566L15.2595 16.615L17.9178 13.9566C18.1595 13.715 18.5595 13.715 18.8012 13.9566C19.0428 14.1983 19.0428 14.5983 18.8012 14.84L15.7011 17.94C15.5845 18.0567 15.4262 18.1233 15.2595 18.1233C15.0928 18.1233 14.9345 18.0567 14.8178 17.94L11.7178 14.84C11.6012 14.715 11.5345 14.5567 11.5345 14.3983Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    id: "Vector_2",
    d: "M14.6328 17.5L14.6328 2.5C14.6328 2.15833 14.9161 1.875 15.2578 1.875C15.5995 1.875 15.8828 2.15833 15.8828 2.5L15.8828 17.5C15.8828 17.8417 15.5995 18.125 15.2578 18.125C14.9161 18.125 14.6328 17.8417 14.6328 17.5Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    id: "Vector_3",
    d: "M2.74156 5.59998C2.74156 5.44164 2.79987 5.28333 2.92487 5.15833L6.0249 2.05831C6.14156 1.94164 6.29987 1.875 6.46654 1.875C6.6332 1.875 6.79156 1.94164 6.90823 2.05831L10.0082 5.15834C10.2499 5.4 10.2499 5.8 10.0082 6.04167C9.76654 6.28333 9.36654 6.28333 9.12487 6.04167L6.46654 3.38333L3.80825 6.04167C3.56658 6.28333 3.16654 6.28333 2.92487 6.04167C2.79987 5.925 2.74156 5.76664 2.74156 5.59998Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    id: "Vector_4",
    d: "M5.85156 17.5L5.85156 2.5C5.85156 2.15833 6.1349 1.875 6.47656 1.875C6.81823 1.875 7.10156 2.15833 7.10156 2.5L7.10156 17.5C7.10156 17.8417 6.81823 18.125 6.47656 18.125C6.1349 18.125 5.85156 17.8417 5.85156 17.5Z"
  }));
}


exports.default = ArrowSwapIcon;
