"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function SwapIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 24 25",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M15.0002 23.25C14.7302 23.25 14.4802 23.1 14.3502 22.87C14.2202 22.64 14.2202 22.35 14.3602 22.12L15.4102 20.37C15.6202 20.01 16.0802 19.9 16.4402 20.11C16.8002 20.32 16.9102 20.78 16.7002 21.14L16.4302 21.59C19.1902 20.94 21.2602 18.46 21.2602 15.5C21.2602 15.09 21.6002 14.75 22.0102 14.75C22.4202 14.75 22.7602 15.09 22.7602 15.5C22.7502 19.77 19.2702 23.25 15.0002 23.25Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M2 10.25C1.59 10.25 1.25 9.91 1.25 9.5C1.25 5.23 4.73 1.75 9 1.75C9.27 1.75 9.51999 1.9 9.64999 2.13C9.77999 2.36 9.78 2.65 9.64 2.88L8.59 4.63C8.38 4.99001 7.92 5.10001 7.56 4.89001C7.2 4.68001 7.09 4.21999 7.3 3.85999L7.57001 3.40997C4.81001 4.05997 2.74001 6.54 2.74001 9.5C2.75001 9.91 2.41 10.25 2 10.25Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M17.6799 7.99987C17.5499 7.99987 17.4199 7.9699 17.2999 7.8999L13.3299 5.59985C12.9699 5.38985 12.8499 4.92988 13.0599 4.56988C13.2699 4.20988 13.7299 4.08986 14.0799 4.29986L17.6799 6.37988L21.2499 4.30987C21.6099 4.09987 22.0699 4.22989 22.2699 4.57989C22.4799 4.93989 22.3499 5.39986 21.9999 5.60986L18.0499 7.88989C17.9399 7.95989 17.8099 7.99987 17.6799 7.99987Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M17.6802 12.0699C17.2702 12.0699 16.9302 11.7299 16.9302 11.3199V7.23987C16.9302 6.82987 17.2702 6.48987 17.6802 6.48987C18.0902 6.48987 18.4302 6.82987 18.4302 7.23987V11.3199C18.4302 11.7399 18.0902 12.0699 17.6802 12.0699Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M17.6799 12.2499C17.2199 12.2499 16.7499 12.1499 16.3799 11.9399L13.9799 10.6099C13.1999 10.1799 12.6099 9.16997 12.6099 8.27997V5.73993C12.6099 4.83993 13.1999 3.83997 13.9899 3.39997L16.3899 2.06995C17.1299 1.65995 18.2399 1.65995 18.9899 2.06995L21.3899 3.39997C22.1699 3.82997 22.7599 4.83992 22.7599 5.72992V8.26996C22.7599 9.16996 22.1699 10.1699 21.3899 10.5999L18.9899 11.9299C18.5999 12.1499 18.1399 12.2499 17.6799 12.2499ZM17.1099 3.36994L14.7099 4.69996C14.4099 4.86996 14.1099 5.37991 14.1099 5.71991V8.25995C14.1099 8.60995 14.4099 9.11997 14.7099 9.27997L17.1099 10.6199C17.3999 10.7799 17.9599 10.7799 18.2499 10.6199L20.6499 9.28992C20.9499 9.11992 21.2499 8.60996 21.2499 8.26996V5.72992C21.2499 5.37992 20.9499 4.86997 20.6499 4.70997L18.2499 3.37995C17.9599 3.20995 17.3899 3.20994 17.1099 3.36994Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M6.32002 18.9999C6.19002 18.9999 6.06002 18.9699 5.94002 18.8999L1.97002 16.5998C1.61002 16.3898 1.49001 15.9299 1.70001 15.5699C1.91001 15.2099 2.37002 15.0899 2.72002 15.2999L6.32002 17.3799L9.89001 15.3099C10.25 15.0999 10.71 15.2299 10.91 15.5799C11.12 15.9399 10.99 16.3999 10.64 16.6099L6.69002 18.8899C6.58002 18.9599 6.45002 18.9999 6.32002 18.9999Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M6.31982 23.0699C5.90982 23.0699 5.56982 22.7299 5.56982 22.3199V18.2399C5.56982 17.8299 5.90982 17.4899 6.31982 17.4899C6.72982 17.4899 7.06982 17.8299 7.06982 18.2399V22.3199C7.06982 22.7399 6.73982 23.0699 6.31982 23.0699Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M6.32001 23.2499C5.86001 23.2499 5.39 23.1499 5.02 22.9399L2.62 21.6099C1.84 21.1799 1.25 20.17 1.25 19.28V16.7399C1.25 15.8399 1.84 14.8399 2.62 14.4099L5.02 13.08C5.76 12.67 6.88 12.67 7.62 13.08L10.02 14.4099C10.8 14.8399 11.39 15.8499 11.39 16.7399V19.28C11.39 20.18 10.8 21.1799 10.01 21.6199L7.61 22.95C7.25 23.15 6.79001 23.2499 6.32001 23.2499ZM5.75 14.3699L3.35001 15.7C3.05001 15.87 2.75 16.3799 2.75 16.7199V19.26C2.75 19.61 3.05001 20.12 3.35001 20.28L5.75 21.6099C6.04 21.7699 6.6 21.7699 6.89 21.6099L9.28999 20.28C9.58999 20.11 9.89 19.6 9.89 19.26V16.7199C9.89 16.3699 9.58999 15.86 9.28999 15.7L6.89 14.3599C6.61 14.2099 6.04 14.2099 5.75 14.3699Z"
  }));
}


exports.default = SwapIcon;
