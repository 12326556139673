"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function EyeSlashIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 25 25",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    id: "Vector",
    d: "M9.96992 15.7819C9.77992 15.7819 9.58992 15.7119 9.43992 15.5619C8.61992 14.7419 8.16992 13.6519 8.16992 12.5019C8.16992 10.1119 10.1099 8.17188 12.4999 8.17188C13.6499 8.17188 14.7399 8.62187 15.5599 9.44187C15.6999 9.58187 15.7799 9.77188 15.7799 9.97188C15.7799 10.1719 15.6999 10.3619 15.5599 10.5019L10.4999 15.5619C10.3499 15.7119 10.1599 15.7819 9.96992 15.7819ZM12.4999 9.67188C10.9399 9.67188 9.66992 10.9419 9.66992 12.5019C9.66992 13.0019 9.79992 13.4819 10.0399 13.9019L13.8999 10.0419C13.4799 9.80187 12.9999 9.67188 12.4999 9.67188Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    id: "Vector_2",
    d: "M6.09984 19.0105C5.92984 19.0105 5.74984 18.9505 5.60984 18.8305C4.53984 17.9205 3.57984 16.8005 2.75984 15.5005C1.69984 13.8505 1.69984 11.1605 2.75984 9.50047C5.19984 5.68047 8.74984 3.48047 12.4998 3.48047C14.6998 3.48047 16.8698 4.24047 18.7698 5.67047C19.0998 5.92047 19.1698 6.39047 18.9198 6.72047C18.6698 7.05047 18.1998 7.12047 17.8698 6.87047C16.2298 5.63047 14.3698 4.98047 12.4998 4.98047C9.26984 4.98047 6.17984 6.92047 4.01984 10.3105C3.26984 11.4805 3.26984 13.5205 4.01984 14.6905C4.76984 15.8605 5.62984 16.8705 6.57984 17.6905C6.88984 17.9605 6.92984 18.4305 6.65984 18.7505C6.51984 18.9205 6.30984 19.0105 6.09984 19.0105Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    id: "Vector_3",
    d: "M12.5006 21.5195C11.1706 21.5195 9.87055 21.2495 8.62055 20.7195C8.24055 20.5595 8.06055 20.1195 8.22055 19.7395C8.38055 19.3595 8.82055 19.1795 9.20055 19.3395C10.2606 19.7895 11.3706 20.0195 12.4906 20.0195C15.7206 20.0195 18.8106 18.0795 20.9706 14.6895C21.7206 13.5195 21.7206 11.4795 20.9706 10.3095C20.6606 9.81951 20.3205 9.34951 19.9606 8.90951C19.7006 8.58951 19.7506 8.11951 20.0706 7.84951C20.3906 7.58951 20.8605 7.62951 21.1306 7.95951C21.5206 8.43951 21.9006 8.95951 22.2406 9.49951C23.3006 11.1495 23.3006 13.8395 22.2406 15.4995C19.8006 19.3195 16.2506 21.5195 12.5006 21.5195Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    id: "Vector_4",
    d: "M13.1896 16.7684C12.8396 16.7684 12.5196 16.5184 12.4496 16.1584C12.3696 15.7484 12.6396 15.3584 13.0496 15.2884C14.1496 15.0884 15.0696 14.1684 15.2696 13.0684C15.3496 12.6584 15.7396 12.3984 16.1496 12.4684C16.5596 12.5484 16.8296 12.9384 16.7496 13.3484C16.4296 15.0784 15.0496 16.4484 13.3296 16.7684C13.2796 16.7584 13.2396 16.7684 13.1896 16.7684Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    id: "Vector_5",
    d: "M2.49945 23.2487C2.30945 23.2487 2.11945 23.1788 1.96945 23.0288C1.67945 22.7388 1.67945 22.2587 1.96945 21.9687L9.43945 14.4988C9.72945 14.2088 10.2095 14.2088 10.4995 14.4988C10.7895 14.7887 10.7895 15.2688 10.4995 15.5588L3.02945 23.0288C2.87945 23.1788 2.68945 23.2487 2.49945 23.2487Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    id: "Vector_6",
    d: "M15.0307 10.7214C14.8407 10.7214 14.6507 10.6514 14.5007 10.5014C14.2107 10.2114 14.2107 9.73141 14.5007 9.44141L21.9707 1.97141C22.2607 1.68141 22.7407 1.68141 23.0307 1.97141C23.3207 2.26141 23.3207 2.74141 23.0307 3.03141L15.5607 10.5014C15.4107 10.6514 15.2207 10.7214 15.0307 10.7214Z"
  }));
}


exports.default = EyeSlashIcon;
