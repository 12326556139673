"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function OutlookIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 200 175",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M178.725 0H71.275A8.775 8.775 0 0 0 62.5 8.775v9.975l60.563 18.75L187.5 18.75V8.775A8.775 8.775 0 0 0 178.725 0z",
    fill: "#0364b8"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M197.813 96.281c.915-2.878 2.187-5.855 2.187-8.781-.002-1.485-.795-2.857-1.491-3.26l-68.434-38.99a9.37 9.37 0 0 0-9.244-.519c-.312.154-.614.325-.906.512l-67.737 38.6-.025.013-.075.044a4.16 4.16 0 0 0-2.088 3.6c.541 2.971 1.272 5.904 2.188 8.781l71.825 52.532z",
    fill: "#0a2767"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M150 18.75h-43.75L93.619 37.5l12.631 18.75L150 93.75h37.5v-37.5z",
    fill: "#28a8ea"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M150 18.75h37.5v37.5H150z",
    fill: "#50d9ff"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M150 93.75l-43.75-37.5H62.5v37.5l43.75 37.5 67.7 11.05z",
    fill: "#0364b8"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M106.25 56.25v37.5H150v-37.5zM150 93.75v37.5h37.5v-37.5zm-87.5-75h43.75v37.5H62.5z",
    fill: "#0078d4"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M62.5 93.75h43.75v37.5H62.5z",
    fill: "#064a8c"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M126.188 145.113l-73.706-53.75 3.094-5.438 68.181 38.825a3.3 3.3 0 0 0 2.625-.075l68.331-38.937 3.1 5.431z",
    fill: "#0a2767",
    opacity: ".5"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M197.919 91.106l-.088.05-.019.013-67.738 38.588c-2.736 1.764-6.192 1.979-9.125.569l23.588 31.631 51.588 11.257v-.001c2.434-1.761 3.876-4.583 3.875-7.587V87.5c.001 1.488-.793 2.862-2.081 3.606z",
    fill: "#1490df"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M200 165.625v-4.613l-62.394-35.55-7.531 4.294a9.356 9.356 0 0 1-9.125.569l23.588 31.631 51.588 11.231v.025a9.362 9.362 0 0 0 3.875-7.588z",
    opacity: ".05"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M199.688 168.019l-68.394-38.956-1.219.688c-2.734 1.766-6.19 1.984-9.125.575l23.588 31.631 51.587 11.256v.001a9.38 9.38 0 0 0 3.562-5.187z",
    opacity: ".1"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M51.455 90.721c-.733-.467-1.468-1.795-1.455-3.221v78.125c-.007 5.181 4.194 9.382 9.375 9.375h131.25c1.395-.015 2.614-.366 3.813-.813.638-.258 1.252-.652 1.687-.974z",
    fill: "#28a8ea"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M112.5 141.669V39.581a8.356 8.356 0 0 0-8.331-8.331H62.687v46.6l-10.5 5.987-.031.012-.075.044A4.162 4.162 0 0 0 50 87.5v.031-.031V150h54.169a8.356 8.356 0 0 0 8.331-8.331z",
    opacity: ".1"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M106.25 147.919V45.831a8.356 8.356 0 0 0-8.331-8.331H62.687v40.35l-10.5 5.987-.031.012-.075.044A4.162 4.162 0 0 0 50 87.5v.031-.031 68.75h47.919a8.356 8.356 0 0 0 8.331-8.331z",
    opacity: ".2"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M106.25 135.419V45.831a8.356 8.356 0 0 0-8.331-8.331H62.687v40.35l-10.5 5.987-.031.012-.075.044A4.162 4.162 0 0 0 50 87.5v.031-.031 56.25h47.919a8.356 8.356 0 0 0 8.331-8.331z",
    opacity: ".2"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M100 135.419V45.831a8.356 8.356 0 0 0-8.331-8.331H62.687v40.35l-10.5 5.987-.031.012-.075.044A4.162 4.162 0 0 0 50 87.5v.031-.031 56.25h41.669a8.356 8.356 0 0 0 8.331-8.331z",
    opacity: ".2"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M8.331 37.5h83.337A8.331 8.331 0 0 1 100 45.831v83.338a8.331 8.331 0 0 1-8.331 8.331H8.331A8.331 8.331 0 0 1 0 129.169V45.831A8.331 8.331 0 0 1 8.331 37.5z",
    fill: "#0078d4"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M24.169 71.675a26.131 26.131 0 0 1 10.263-11.337 31.031 31.031 0 0 1 16.313-4.087 28.856 28.856 0 0 1 15.081 3.875 25.875 25.875 0 0 1 9.988 10.831 34.981 34.981 0 0 1 3.5 15.938 36.881 36.881 0 0 1-3.606 16.662 26.494 26.494 0 0 1-10.281 11.213 30 30 0 0 1-15.656 3.981 29.556 29.556 0 0 1-15.425-3.919 26.275 26.275 0 0 1-10.112-10.85 34.119 34.119 0 0 1-3.544-15.744 37.844 37.844 0 0 1 3.481-16.563zm10.938 26.613a16.975 16.975 0 0 0 5.769 7.463 15.069 15.069 0 0 0 9.019 2.719 15.831 15.831 0 0 0 9.631-2.806 16.269 16.269 0 0 0 5.606-7.481 28.913 28.913 0 0 0 1.787-10.406 31.644 31.644 0 0 0-1.687-10.538 16.681 16.681 0 0 0-5.413-7.75 14.919 14.919 0 0 0-9.544-2.956 15.581 15.581 0 0 0-9.231 2.744 17.131 17.131 0 0 0-5.9 7.519 29.85 29.85 0 0 0-.044 21.5z",
    fill: "#fff"
  }));
}


exports.default = OutlookIcon;
