"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function Wallet3Icon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 16 17",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M8.66602 8.43359H4.66602C4.39268 8.43359 4.16602 8.20693 4.16602 7.93359C4.16602 7.66026 4.39268 7.43359 4.66602 7.43359H8.66602C8.93935 7.43359 9.16602 7.66026 9.16602 7.93359C9.16602 8.20693 8.93935 8.43359 8.66602 8.43359Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M1.33398 8.43453C1.06065 8.43453 0.833984 8.20786 0.833984 7.93453V4.85453C0.833984 3.2212 2.16065 1.89453 3.79399 1.89453H7.54065C9.25398 1.89453 10.5007 3.03453 10.5007 4.6012C10.5007 4.87453 10.274 5.1012 10.0007 5.1012C9.72732 5.1012 9.50065 4.87453 9.50065 4.6012C9.50065 3.42786 8.48065 2.89453 7.54065 2.89453H3.79399C2.71399 2.89453 1.83398 3.77453 1.83398 4.85453V7.93453C1.83398 8.21453 1.60732 8.43453 1.33398 8.43453Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M10.6673 15.1016H4.00065C2.25398 15.1016 0.833984 13.6816 0.833984 11.9349V7.26823C0.833984 5.52156 2.25398 4.10156 4.00065 4.10156H10.6673C12.414 4.10156 13.834 5.52156 13.834 7.26823V8.23486C13.834 8.5082 13.6073 8.73486 13.334 8.73486H12.614C12.3806 8.73486 12.1673 8.82153 12.014 8.98153L12.0073 8.9882C11.7873 9.2082 11.7006 9.51486 11.7806 9.81486C11.8806 10.1882 12.274 10.4615 12.714 10.4615H13.334C13.6073 10.4615 13.834 10.6882 13.834 10.9615V11.9282C13.834 13.6816 12.414 15.1016 10.6673 15.1016ZM4.00065 5.10156C2.80732 5.10156 1.83398 6.0749 1.83398 7.26823V11.9349C1.83398 13.1282 2.80732 14.1016 4.00065 14.1016H10.6673C11.8607 14.1016 12.834 13.1282 12.834 11.9349V11.4682H12.714C11.814 11.4682 11.034 10.9015 10.814 10.0815C10.6406 9.43488 10.8273 8.7482 11.3007 8.28154C11.6473 7.9282 12.114 7.73486 12.614 7.73486H12.834V7.26823C12.834 6.0749 11.8607 5.10156 10.6673 5.10156H4.00065Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M13.9793 11.4677H12.6926C11.6859 11.4677 10.8326 10.7211 10.7526 9.76107C10.6993 9.20773 10.8993 8.66771 11.2993 8.27437C11.6326 7.92771 12.1059 7.73438 12.6059 7.73438H13.9726C14.626 7.73438 15.1593 8.26105 15.1593 8.91439V10.2877C15.1659 10.9411 14.6326 11.4677 13.9793 11.4677ZM12.6126 8.73438C12.3793 8.73438 12.166 8.82104 12.0126 8.98104C11.8193 9.16771 11.726 9.42106 11.7526 9.6744C11.786 10.1144 12.2126 10.4677 12.6926 10.4677H13.9793C14.0793 10.4677 14.166 10.3877 14.166 10.2877V8.91439C14.166 8.81439 14.0793 8.73438 13.9793 8.73438H12.6126Z"
  }));
}


exports.default = Wallet3Icon;
