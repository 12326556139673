"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function WalletMoneyIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 25 25",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M7.84558 22.8219C5.46558 22.8219 3.51562 21.0919 3.51562 18.9619V16.9219C3.51562 16.5119 3.85563 16.1719 4.26562 16.1719C4.67562 16.1719 5.01562 16.5119 5.01562 16.9219C5.01562 18.1719 6.22558 19.1119 7.84558 19.1119C9.46558 19.1119 10.6756 18.1719 10.6756 16.9219C10.6756 16.5119 11.0156 16.1719 11.4256 16.1719C11.8356 16.1719 12.1756 16.5119 12.1756 16.9219V18.9619C12.1756 21.0919 10.2356 22.8219 7.84558 22.8219ZM5.27557 19.9419C5.71557 20.7619 6.70558 21.3219 7.84558 21.3219C8.98558 21.3219 9.97559 20.7519 10.4156 19.9419C9.70559 20.3719 8.82558 20.6219 7.84558 20.6219C6.86558 20.6219 5.98557 20.3719 5.27557 19.9419Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M7.84558 17.8717C6.20558 17.8717 4.73562 17.1217 4.00562 15.9317C3.68562 15.4117 3.51562 14.8017 3.51562 14.1817C3.51562 13.1317 3.97561 12.1517 4.81561 11.4217C6.43561 10.0017 9.22559 10.0017 10.8556 11.4117C11.6956 12.1517 12.1656 13.1317 12.1656 14.1817C12.1656 14.8017 11.9956 15.4117 11.6756 15.9317C10.9556 17.1217 9.48558 17.8717 7.84558 17.8717ZM7.84558 11.8217C7.06558 11.8217 6.3456 12.0817 5.8056 12.5517C5.2956 12.9917 5.01562 13.5717 5.01562 14.1817C5.01562 14.5317 5.10558 14.8517 5.28558 15.1517C5.74558 15.9117 6.72558 16.3817 7.84558 16.3817C8.96558 16.3817 9.94557 15.9117 10.3956 15.1617C10.5756 14.8717 10.6656 14.5417 10.6656 14.1917C10.6656 13.5817 10.3856 13.0017 9.87561 12.5517C9.34561 12.0817 8.62558 11.8217 7.84558 11.8217Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M7.84558 20.6223C5.37558 20.6223 3.51562 19.0323 3.51562 16.9323V14.1823C3.51562 12.0523 5.45558 10.3223 7.84558 10.3223C8.97558 10.3223 10.0556 10.7123 10.8656 11.4123C11.7056 12.1523 12.1756 13.1323 12.1756 14.1823V16.9323C12.1756 19.0323 10.3156 20.6223 7.84558 20.6223ZM7.84558 11.8223C6.28558 11.8223 5.01562 12.8823 5.01562 14.1823V16.9323C5.01562 18.1823 6.22558 19.1223 7.84558 19.1223C9.46558 19.1223 10.6756 18.1823 10.6756 16.9323V14.1823C10.6756 13.5723 10.3956 12.9922 9.88562 12.5422C9.34562 12.0822 8.62558 11.8223 7.84558 11.8223Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M19.7157 14.8715C18.2057 14.8715 16.9257 13.7515 16.8057 12.3115C16.7257 11.4815 17.0257 10.6715 17.6257 10.0815C18.1257 9.56148 18.8357 9.27148 19.5857 9.27148H21.6757C22.6657 9.30148 23.4257 10.0814 23.4257 11.0414V13.1015C23.4257 14.0615 22.6657 14.8415 21.7057 14.8715H19.7157ZM21.6457 10.7715H19.5957C19.2457 10.7715 18.9257 10.9015 18.6957 11.1415C18.4057 11.4215 18.2657 11.8015 18.3057 12.1815C18.3557 12.8415 18.9957 13.3715 19.7157 13.3715H21.6757C21.8057 13.3715 21.9257 13.2515 21.9257 13.1015V11.0414C21.9257 10.8914 21.8057 10.7815 21.6457 10.7715Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M16.676 21.3223H14.176C13.766 21.3223 13.426 20.9823 13.426 20.5723C13.426 20.1623 13.766 19.8223 14.176 19.8223H16.676C19.256 19.8223 20.926 18.1523 20.926 15.5723V14.8723H19.716C18.206 14.8723 16.926 13.7523 16.806 12.3123C16.726 11.4823 17.026 10.6723 17.626 10.0823C18.126 9.56228 18.836 9.27228 19.586 9.27228H20.916V8.57227C20.916 6.23227 19.546 4.62224 17.326 4.36224C17.0861 4.32224 16.876 4.32227 16.666 4.32227H7.66602C7.42602 4.32227 7.196 4.34225 6.966 4.37225C4.766 4.65225 3.41602 6.25227 3.41602 8.57227V10.5723C3.41602 10.9823 3.07602 11.3223 2.66602 11.3223C2.25602 11.3223 1.91602 10.9823 1.91602 10.5723V8.57227C1.91602 5.49227 3.81605 3.26227 6.76605 2.89227C7.03605 2.85227 7.34602 2.82227 7.66602 2.82227H16.666C16.906 2.82227 17.216 2.83226 17.536 2.88226C20.486 3.22226 22.416 5.46227 22.416 8.57227V10.0223C22.416 10.4323 22.076 10.7723 21.666 10.7723H19.586C19.236 10.7723 18.916 10.9023 18.686 11.1423C18.396 11.4223 18.256 11.8023 18.296 12.1823C18.346 12.8423 18.9861 13.3723 19.7061 13.3723H21.676C22.086 13.3723 22.426 13.7123 22.426 14.1223V15.5723C22.426 19.0123 20.116 21.3223 16.676 21.3223Z"
  }));
}


exports.default = WalletMoneyIcon;
