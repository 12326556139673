"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _ = require('..');
var _isUndefined = require('lodash/isUndefined'); var _isUndefined2 = _interopRequireDefault(_isUndefined);
var _styledcomponents = require('styled-components'); var _styledcomponents2 = _interopRequireDefault(_styledcomponents);
var _Input = require('@mui/material/Input'); var _Input2 = _interopRequireDefault(_Input);
var _icons = require('../../icons');
var _theme = require('../../theme');
var _typography = require('../../theme/typography');
var _reactintl = require('react-intl');
var _viem = require('viem');
var _material = require('@mui/material');
var _currency = require('../../common/utils/currency');
var _mui = require('tss-react/mui');
var _sdk = require('@balmy/sdk');
const MIN_AMOUNT_FOR_MAX_DEDUCTION = {
  [_sdk.Chains.POLYGON.chainId]: _viem.parseUnits.call(void 0, "0.1", 18),
  [_sdk.Chains.BNB_CHAIN.chainId]: _viem.parseUnits.call(void 0, "0.1", 18),
  [_sdk.Chains.ARBITRUM.chainId]: _viem.parseUnits.call(void 0, "0.001", 18),
  [_sdk.Chains.OPTIMISM.chainId]: _viem.parseUnits.call(void 0, "0.001", 18),
  [_sdk.Chains.ETHEREUM.chainId]: _viem.parseUnits.call(void 0, "0.1", 18),
  [_sdk.Chains.BASE_GOERLI.chainId]: _viem.parseUnits.call(void 0, "0.1", 18),
  [_sdk.Chains.GNOSIS.chainId]: _viem.parseUnits.call(void 0, "0.1", 18),
  [_sdk.Chains.MOONBEAM.chainId]: _viem.parseUnits.call(void 0, "0.1", 18)
};
const MAX_DEDUCTION = {
  [_sdk.Chains.POLYGON.chainId]: _viem.parseUnits.call(void 0, "0.045", 18),
  [_sdk.Chains.BNB_CHAIN.chainId]: _viem.parseUnits.call(void 0, "0.045", 18),
  [_sdk.Chains.ARBITRUM.chainId]: _viem.parseUnits.call(void 0, "0.00015", 18),
  [_sdk.Chains.OPTIMISM.chainId]: _viem.parseUnits.call(void 0, "0.000525", 18),
  [_sdk.Chains.ETHEREUM.chainId]: _viem.parseUnits.call(void 0, "0.021", 18),
  [_sdk.Chains.BASE_GOERLI.chainId]: _viem.parseUnits.call(void 0, "0.021", 18),
  [_sdk.Chains.GNOSIS.chainId]: _viem.parseUnits.call(void 0, "0.1", 18),
  [_sdk.Chains.MOONBEAM.chainId]: _viem.parseUnits.call(void 0, "0.1", 18)
};
const getMinAmountForMaxDeduction = (chainId) => MIN_AMOUNT_FOR_MAX_DEDUCTION[chainId] || _viem.parseUnits.call(void 0, "0.1", 18);
const getMaxDeduction = (chainId) => MAX_DEDUCTION[chainId] || _viem.parseUnits.call(void 0, "0.045", 18);
const PROTOCOL_TOKEN_ADDRESS = "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee";
const getInputColor = (_ref) => {
  let {
    disabled,
    hasValue,
    mode
  } = _ref;
  if (disabled) {
    return _theme.colors[mode].typography.typo2;
  } else if (hasValue) {
    return _theme.colors[mode].typography.typo3;
  } else {
    return _theme.colors[mode].typography.typo5;
  }
};
const getSubInputColor = (_ref2) => {
  let {
    hasValue,
    mode
  } = _ref2;
  if (hasValue) {
    return _theme.colors[mode].typography.typo3;
  } else {
    return _theme.colors[mode].typography.typo5;
  }
};
const StyledButton = _styledcomponents2.default.call(void 0, _.Button)`
  min-width: 0;
  padding: 0 !important;
`;
const StyledInput = _styledcomponents2.default.call(void 0, _Input2.default)`
  padding: 0 !important;
  background-color: transparent !important;
`;
const StyledIconButton = _mui.withStyles.call(void 0, _.IconButton, (_ref3) => {
  let {
    palette
  } = _ref3;
  return {
    root: {
      border: `1px solid ${_theme.colors[palette.mode].border.border1}`
    },
    disabled: {
      color: `${_theme.colors[palette.mode].accent.accent600} !important`
    }
  };
});
const calculateUsdAmount = (_ref4) => {
  let {
    value,
    token,
    tokenPrice
  } = _ref4;
  return _isUndefined2.default.call(void 0, value) || value === "" || _isUndefined2.default.call(void 0, tokenPrice) || !token ? "0" : parseFloat(_viem.formatUnits.call(void 0, _viem.parseUnits.call(void 0, value, token.decimals) * tokenPrice, token.decimals + 18)).toFixed(2);
};
const calculateTokenAmount = (_ref5) => {
  let {
    value,
    tokenPrice
  } = _ref5;
  return _isUndefined2.default.call(void 0, value) || value === "" || _isUndefined2.default.call(void 0, tokenPrice) ? "0" : _viem.formatUnits.call(void 0, _viem.parseUnits.call(void 0, value, 18 * 2) / tokenPrice, 18).toString();
};
const validator = (_ref6) => {
  let {
    nextValue,
    decimals,
    onChange
  } = _ref6;
  const newNextValue = nextValue.replace(/,/g, ".");
  const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d{0,${decimals}}$`);
  if (inputRegex.test(newNextValue.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"))) {
    onChange(newNextValue.startsWith(".") ? `0${newNextValue}` : newNextValue || "");
  }
};
const TokenInput = (_ref7) => {
  let {
    onChange,
    value,
    token,
    tokenPrice,
    onBlur,
    onFocus,
    disabled
  } = _ref7;
  const {
    palette: {
      mode
    }
  } = _material.useTheme.call(void 0, );
  const usdAmount = calculateUsdAmount({
    value,
    token,
    tokenPrice
  });
  return /* @__PURE__ */ _react2.default.createElement(_.ContainerBox, {
    flexDirection: "column",
    flex: 1
  }, /* @__PURE__ */ _react2.default.createElement(_.FormControl, {
    variant: "standard",
    fullWidth: true
  }, /* @__PURE__ */ _react2.default.createElement(StyledInput, {
    id: "component-simple",
    onChange: (evt) => validator({
      onChange,
      nextValue: evt.target.value,
      decimals: _optionalChain([token, 'optionalAccess', _3 => _3.decimals]) || 18
    }),
    value: value || "",
    onFocus,
    onBlur,
    autoComplete: "off",
    placeholder: "0.0",
    disableUnderline: true,
    disabled,
    inputProps: {
      style: {
        color: getInputColor({
          disabled,
          mode,
          hasValue: !_isUndefined2.default.call(void 0, value)
        }),
        padding: 0,
        textOverflow: "ellipsis"
      }
    },
    sx: {
      ..._typography.buildTypographyVariant.call(void 0, mode).h4Bold,
      color: "inherit"
    }
  })), /* @__PURE__ */ _react2.default.createElement(_.Typography, {
    variant: "bodySmallRegular",
    color: getSubInputColor({
      mode,
      hasValue: !_isUndefined2.default.call(void 0, value)
    })
  }, `$${usdAmount}`));
};
const UsdInput = (_ref8) => {
  let {
    onChange,
    value,
    token,
    tokenPrice,
    onBlur,
    onFocus,
    disabled
  } = _ref8;
  const {
    palette: {
      mode
    }
  } = _material.useTheme.call(void 0, );
  const tokenAmount = calculateTokenAmount({
    value,
    tokenPrice
  });
  return /* @__PURE__ */ _react2.default.createElement(_.ContainerBox, {
    flexDirection: "column",
    flex: 1
  }, /* @__PURE__ */ _react2.default.createElement(_.FormControl, {
    variant: "standard"
  }, /* @__PURE__ */ _react2.default.createElement(StyledInput, {
    id: "component-simple",
    onChange: (evt) => validator({
      onChange,
      nextValue: evt.target.value,
      decimals: 2
    }),
    value: value || "",
    onFocus,
    onBlur,
    startAdornment: "$",
    autoComplete: "off",
    placeholder: "0.00",
    disableUnderline: true,
    disabled,
    inputProps: {
      style: {
        color: getInputColor({
          disabled,
          mode,
          hasValue: !_isUndefined2.default.call(void 0, value)
        }),
        padding: 0,
        textOverflow: "ellipsis"
      }
    },
    sx: {
      ..._typography.buildTypographyVariant.call(void 0, mode).h4Bold,
      gap: 0,
      color: getInputColor({
        disabled,
        mode,
        hasValue: !_isUndefined2.default.call(void 0, value)
      })
    }
  })), /* @__PURE__ */ _react2.default.createElement(_.Typography, {
    variant: "bodySmallRegular",
    color: getSubInputColor({
      mode,
      hasValue: !_isUndefined2.default.call(void 0, value)
    })
  }, "\u2248", ` ${tokenAmount} ${_optionalChain([token, 'optionalAccess', _4 => _4.symbol])}`));
};
const StyledHeader = _styledcomponents2.default.call(void 0, _.ContainerBox).attrs(() => ({
  gap: 3,
  justifyContent: "center",
  alignItems: "center"
}))``;
const StyledFooter = _styledcomponents2.default.call(void 0, _.ContainerBox).attrs(() => ({
  gap: 3,
  justifyContent: "center",
  alignItems: "center"
}))``;
const StyledEndContent = _styledcomponents2.default.call(void 0, _.ContainerBox).attrs(() => ({
  gap: 3,
  justifyContent: "space-between",
  alignItems: "flex-end",
  flexDirection: "column"
}))`
  ${(_ref9) => {
  let {
    theme: {
      spacing
    }
  } = _ref9;
  return `
    padding: ${spacing(2)} ${spacing(3)};
    padding-left: 0px;
  `;
}}
`;
const InputContentContainer = _styledcomponents2.default.call(void 0, _.ContainerBox).attrs({
  gap: 3
})`
  ${(_ref10) => {
  let {
    theme
  } = _ref10;
  return `
    position: relative;
    flex: 1;
    padding: ${theme.spacing(3)};
    gap: ${theme.spacing(3)};
  `;
}}
`;
var InputTypeT = /* @__PURE__ */ function(InputTypeT2) {
  InputTypeT2["usd"] = "usd";
  InputTypeT2["token"] = "token";
  return InputTypeT2;
}(InputTypeT || {});
const TokenAmounUsdInput = (_ref11) => {
  let {
    token,
    balance,
    tokenPrice,
    value,
    onChange,
    disabled
  } = _ref11;
  const [internalValue, setInternalValue] = _react.useState.call(void 0, value);
  const {
    palette: {
      mode
    }
  } = _material.useTheme.call(void 0, );
  const [isFocused, setIsFocused] = _react.useState.call(void 0, false);
  const [inputType, setInputType] = _react.useState.call(void 0, InputTypeT.token);
  const intl = _reactintl.useIntl.call(void 0, );
  _react.useEffect.call(void 0, () => {
    if (inputType === InputTypeT.token) {
      if (value !== internalValue) {
        setInternalValue(value);
      }
    } else if (inputType === InputTypeT.usd && !_isUndefined2.default.call(void 0, tokenPrice) && token) {
      if (_isUndefined2.default.call(void 0, value)) {
        setInternalValue(void 0);
        return;
      }
      const newInternalValue = calculateUsdAmount({
        value,
        token,
        tokenPrice
      });
      if (!internalValue || newInternalValue !== parseFloat(internalValue).toFixed(2)) {
        setInternalValue(newInternalValue);
      }
    } else {
      throw new Error("invalid inputType");
    }
  }, [value]);
  const onChangeType = () => {
    let newInternalValue;
    if (_isUndefined2.default.call(void 0, tokenPrice)) {
      return;
    }
    if (!_isUndefined2.default.call(void 0, value)) {
      if (inputType === InputTypeT.token && token) {
        newInternalValue = calculateUsdAmount({
          value,
          token,
          tokenPrice
        });
      } else if (inputType === InputTypeT.usd) {
        newInternalValue = calculateTokenAmount({
          value: internalValue || "0",
          tokenPrice
        });
      } else {
        throw new Error("invalid inputType");
      }
    }
    setInputType((oldInputType) => oldInputType === InputTypeT.token ? InputTypeT.usd : InputTypeT.token);
    setInternalValue(newInternalValue);
  };
  const onValueChange = (newValue) => {
    if (inputType === InputTypeT.token) {
      onChange(newValue);
    } else if (inputType === InputTypeT.usd) {
      if (_isUndefined2.default.call(void 0, tokenPrice)) {
        throw new Error("Token price is undefined for inputType usd");
      }
      setInternalValue(newValue);
      onChange(calculateTokenAmount({
        value: newValue,
        tokenPrice
      }));
    } else {
      throw new Error("invalid inputType");
    }
  };
  const onMaxValueClick = () => {
    if (!balance) {
      throw new Error("should not call on max value without a balance");
    }
    if (balance && token && token.address === PROTOCOL_TOKEN_ADDRESS) {
      const maxValue = BigInt(balance.amount) >= getMinAmountForMaxDeduction(token.chainId) ? BigInt(balance.amount) - getMaxDeduction(token.chainId) : BigInt(balance.amount);
      onChange(_viem.formatUnits.call(void 0, maxValue, token.decimals));
    } else {
      onChange(_viem.formatUnits.call(void 0, BigInt(balance.amount), _optionalChain([token, 'optionalAccess', _5 => _5.decimals]) || 18));
    }
  };
  return /* @__PURE__ */ _react2.default.createElement(_.InputContainer, {
    isFocused,
    alignItems: "center",
    disabled,
    padding: "0 !important"
  }, /* @__PURE__ */ _react2.default.createElement(InputContentContainer, null, /* @__PURE__ */ _react2.default.createElement(StyledIconButton, {
    color: "primary",
    disabled: _isUndefined2.default.call(void 0, tokenPrice) || disabled,
    onClick: onChangeType
  }, /* @__PURE__ */ _react2.default.createElement(_icons.ToggleArrowIcon, {
    sx: {
      color: _theme.colors[mode].accent.primary
    }
  })), /* @__PURE__ */ _react2.default.createElement(_.ContainerBox, {
    alignItems: "center",
    gap: 2,
    flex: 1
  }, inputType === InputTypeT.token ? /* @__PURE__ */ _react2.default.createElement(TokenInput, {
    token,
    balance,
    tokenPrice,
    value: internalValue,
    onChange: onValueChange,
    onFocus: () => setIsFocused(true),
    onBlur: () => setIsFocused(false),
    disabled
  }) : /* @__PURE__ */ _react2.default.createElement(UsdInput, {
    token,
    balance,
    tokenPrice,
    value: internalValue,
    onChange: onValueChange,
    onFocus: () => setIsFocused(true),
    onBlur: () => setIsFocused(false),
    disabled
  }))), /* @__PURE__ */ _react2.default.createElement(StyledEndContent, null, /* @__PURE__ */ _react2.default.createElement(StyledHeader, null, /* @__PURE__ */ _react2.default.createElement(_.Typography, {
    variant: "bodySemibold",
    color: getInputColor({
      disabled,
      mode,
      hasValue: !_isUndefined2.default.call(void 0, internalValue)
    })
  }, inputType === InputTypeT.token ? _optionalChain([token, 'optionalAccess', _6 => _6.symbol]) : "USD")), balance && /* @__PURE__ */ _react2.default.createElement(StyledFooter, null, /* @__PURE__ */ _react2.default.createElement(_.Typography, {
    variant: "bodySmallRegular",
    color: _theme.colors[mode].typography.typo3
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "Mb4E1i",
    defaultMessage: [{
      "type": 0,
      "value": "Balance:"
    }]
  }), ` `, _currency.formatCurrencyAmount.call(void 0, {
    amount: balance.amount,
    token: token || void 0,
    intl
  })), /* @__PURE__ */ _react2.default.createElement(StyledButton, {
    size: "small",
    variant: "text",
    onClick: onMaxValueClick,
    disabled
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "twerhs",
    defaultMessage: [{
      "type": 0,
      "value": "Max"
    }]
  })))));
};







exports.MAX_DEDUCTION = MAX_DEDUCTION; exports.MIN_AMOUNT_FOR_MAX_DEDUCTION = MIN_AMOUNT_FOR_MAX_DEDUCTION; exports.PROTOCOL_TOKEN_ADDRESS = PROTOCOL_TOKEN_ADDRESS; exports.TokenAmounUsdInput = TokenAmounUsdInput; exports.getMaxDeduction = getMaxDeduction; exports.getMinAmountForMaxDeduction = getMinAmountForMaxDeduction;
