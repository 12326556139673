"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _colors = require('../colors');
const buildCircularProgressVariant = (mode) => ({
  MuiCircularProgress: {
    styleOverrides: {
      colorSecondary: {
        color: _colors.colors[mode].accent.accent100
      }
    }
  }
});


exports.buildCircularProgressVariant = buildCircularProgressVariant;
