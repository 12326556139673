"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _styledcomponents = require('styled-components'); var _styledcomponents2 = _interopRequireDefault(_styledcomponents);
const ContainerBox = _styledcomponents2.default.div`
  display: flex;
  flex-direction: ${(_ref) => {
  let {
    flexDirection = "row"
  } = _ref;
  return flexDirection;
}};
  justify-content: ${(_ref2) => {
  let {
    justifyContent = "flex-start"
  } = _ref2;
  return justifyContent;
}};
  align-items: ${(_ref3) => {
  let {
    alignItems = "stretch"
  } = _ref3;
  return alignItems;
}};
  flex: ${(_ref4) => {
  let {
    flex = "0 1 auto"
  } = _ref4;
  return flex;
}};
  flex-wrap: ${(_ref5) => {
  let {
    flexWrap = "nowrap"
  } = _ref5;
  return flexWrap;
}};
  ${(_ref6) => {
  let {
    flexGrow
  } = _ref6;
  return flexGrow && `flex-grow: ${flexGrow};`;
}}
  align-self: ${(_ref7) => {
  let {
    alignSelf = "auto"
  } = _ref7;
  return alignSelf;
}};
  width: ${(_ref8) => {
  let {
    fullWidth
  } = _ref8;
  return fullWidth && "100%";
}};
  gap: ${(_ref9) => {
  let {
    gap,
    theme: {
      spacing
    }
  } = _ref9;
  return gap && spacing(gap);
}};
`;


exports.ContainerBox = ContainerBox;
