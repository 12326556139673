"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _ = require('../');
var _styledcomponents = require('styled-components'); var _styledcomponents2 = _interopRequireDefault(_styledcomponents);
const StyledPaper = _styledcomponents2.default.call(void 0, _.Paper)`
  padding: 16px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  display: flex;
  flex: 1;
  align-items: flex-start;
`;
const StyledLabelContainer = _styledcomponents2.default.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  align-self: stretch;
  justify-content: space-evenly;
`;
const StyledBreakdownContainer = _styledcomponents2.default.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const DashboardPopper = (_ref) => {
  let {
    breakdown,
    valueFormatter
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(StyledPaper, {
    variant: "outlined"
  }, /* @__PURE__ */ _react2.default.createElement(StyledLabelContainer, null, breakdown.map((_ref2) => {
    let {
      name,
      value
    } = _ref2;
    return /* @__PURE__ */ _react2.default.createElement(StyledBreakdownContainer, {
      key: name
    }, /* @__PURE__ */ _react2.default.createElement(_.Typography, {
      variant: "bodySmallRegular"
    }, name, ": ", valueFormatter(value)));
  })));
};
var popper_default = DashboardPopper;


exports.default = popper_default;
