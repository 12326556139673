"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _SvgIcon = require('@mui/material/SvgIcon'); var _SvgIcon2 = _interopRequireDefault(_SvgIcon);
const CustomSvgIcon = (_ref) => {
  let {
    viewBox,
    size,
    children,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_SvgIcon2.default, _extends({
    viewBox,
    style: size ? {
      fontSize: size
    } : {}
  }, props), children);
};



exports.CustomSvgIcon = CustomSvgIcon; exports.SvgIcon = _SvgIcon2.default;
