"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _colors = require('../colors');
var _constants = require('../constants');
const buildLinearProgressVariant = (mode) => ({
  MuiLinearProgress: {
    styleOverrides: {
      root: {
        height: _constants.SPACING.call(void 0, 2.25),
        borderRadius: _constants.SPACING.call(void 0, 8.5),
        background: _colors.colors[mode].accentPrimary
      },
      bar: {
        borderRadius: _constants.SPACING.call(void 0, 8.5)
      }
    }
  }
});


exports.buildLinearProgressVariant = buildLinearProgressVariant;
