"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _material = require('@mui/material');
var _styledcomponents = require('styled-components'); var _styledcomponents2 = _interopRequireDefault(_styledcomponents);
const StyledToggleButtonGroup = _styledcomponents2.default.call(void 0, _material.ToggleButtonGroup)`
  display: inline-grid;
  grid-template-columns: ${(_ref) => {
  let {
    $columns
  } = _ref;
  return `repeat(${$columns}, 1fr)`;
}};
  width: max-content;
`;
const OptionsButtons = (_ref2) => {
  let {
    options,
    activeOption,
    setActiveOption,
    size = "medium"
  } = _ref2;
  const handleSelection = (_event, newValue) => {
    if (newValue) {
      setActiveOption(newValue);
    }
  };
  return /* @__PURE__ */ _react2.default.createElement(StyledToggleButtonGroup, {
    $columns: options.length,
    value: activeOption,
    exclusive: true,
    onChange: handleSelection,
    "aria-label": "options-buttons"
  }, options.map((option) => /* @__PURE__ */ _react2.default.createElement(_material.ToggleButton, {
    key: option.value,
    value: option.value,
    "aria-label": `option-${option.value}`,
    sx: {
      minWidth: "95px",
      minHeight: "56px"
    }
  }, /* @__PURE__ */ _react2.default.createElement(_material.Typography, {
    variant: size === "medium" ? "bodyRegular" : "bodySmallRegular"
  }, option.text || option.value.toString()))));
};


exports.OptionsButtons = OptionsButtons;
