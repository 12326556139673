"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _styledcomponents = require('styled-components'); var _styledcomponents2 = _interopRequireDefault(_styledcomponents);
var _menu = require('../menu');
var _menuitem = require('../menuitem');
var _button = require('../button');
var _icons = require('../../icons');
var _common = require('../../common');
var _mui = require('tss-react/mui');
const StyledButton = _styledcomponents2.default.call(void 0, _button.Button)`
  white-space: nowrap;
`;
const StyledButtonGroup = _styledcomponents2.default.div`
  gap: 1px;
  display: flex;

  ${(_ref) => {
  let {
    fullWidth
  } = _ref;
  return fullWidth && "width: 100%;";
}}

  ${StyledButton} {
    ${(_ref2) => {
  let {
    block
  } = _ref2;
  return !block && "border-radius: 30px;";
}}
  }

  ${StyledButton}:first-child {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  ${StyledButton}:last-child {
    min-width: 0px;
    padding-left: ${(_ref3) => {
  let {
    block
  } = _ref3;
  return block ? "1" : "0";
}}px;
    padding-right: ${(_ref4) => {
  let {
    block
  } = _ref4;
  return block ? "1" : "5";
}}px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
`;
const SplitButtonContainer = _styledcomponents2.default.div`
  display: flex;
  ${(_ref5) => {
  let {
    fullWidth
  } = _ref5;
  return fullWidth && "width: 100%;";
}}
`;
const StyledMenu = _mui.withStyles.call(void 0, _menu.Menu, () => _common.createStyles.call(void 0, {
  paper: {
    borderRadius: "8px"
  }
}));
const SplitButton = (_ref6) => {
  let {
    onClick,
    text,
    disabled,
    variant,
    color,
    options,
    fullWidth,
    size = "small",
    block
  } = _ref6;
  const [open, setOpen] = _react.useState.call(void 0, false);
  const anchorRef = _react.useRef.call(void 0, null);
  const handleOpenClose = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const isOptionsButtonDisabled = options.every((_ref7) => {
    let {
      disabled: isDisabled
    } = _ref7;
    return isDisabled;
  });
  return /* @__PURE__ */ _react2.default.createElement(SplitButtonContainer, {
    fullWidth
  }, /* @__PURE__ */ _react2.default.createElement(StyledButtonGroup, {
    ref: anchorRef,
    fullWidth,
    block
  }, /* @__PURE__ */ _react2.default.createElement(StyledButton, {
    onClick,
    disabled,
    color,
    variant,
    size,
    fullWidth
  }, text), /* @__PURE__ */ _react2.default.createElement(StyledButton, {
    onClick: handleOpenClose,
    color,
    variant,
    size,
    disabled: isOptionsButtonDisabled
  }, /* @__PURE__ */ _react2.default.createElement(_icons.ArrowDropDownIcon, null))), /* @__PURE__ */ _react2.default.createElement(StyledMenu, {
    anchorEl: anchorRef.current,
    open,
    onClose: handleClose,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "right"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "right"
    }
  }, options.map((_ref8, index) => {
    let {
      onClick: onClickItem,
      disabled: disabledItem,
      text: itemText
    } = _ref8;
    return /* @__PURE__ */ _react2.default.createElement(_menuitem.MenuItem, {
      key: index,
      onClick: () => {
        onClickItem();
        handleClose();
      },
      disabled: disabledItem
    }, itemText);
  })));
};


exports.SplitButton = SplitButton;
