"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function GithubIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 19 19",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("g", {
    clipPath: "url(#clip0_4410_7078)"
  }, /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M9.3951 1.50411C7.37436 1.49003 5.41522 2.19915 3.87163 3.50334C2.32804 4.80754 1.30181 6.62081 0.978337 8.61554C0.654867 10.6103 1.05549 12.6549 2.10783 14.3801C3.16018 16.1052 4.79483 17.3971 6.71647 18.0224C7.13982 18.0916 7.27837 17.8068 7.27837 17.599V16.1827C4.94611 16.6753 4.45349 15.0589 4.45349 15.0589C4.03014 14.066 3.46824 13.7889 3.46824 13.7889C2.7601 13.2886 3.54521 13.2886 3.54521 13.2886C4.39191 13.2886 4.87683 14.1353 4.87683 14.1353C5.58498 15.4053 6.85502 15.0589 7.34764 14.8434C7.34726 14.631 7.39083 14.4209 7.47561 14.2261C7.56039 14.0314 7.68454 13.8563 7.84026 13.7119C5.93905 13.5041 3.96086 12.7959 3.96086 9.47844C3.96086 8.63174 4.31494 7.86202 4.88453 7.29243C4.73359 6.93564 4.65581 6.55218 4.65581 6.16478C4.65581 5.77738 4.73359 5.39392 4.88453 5.03714C4.88453 5.03714 5.58498 4.82162 7.27837 5.88383C8.66387 5.50667 10.1263 5.50667 11.5118 5.88383C13.1283 4.82162 13.8364 5.03714 13.8364 5.03714C13.9873 5.39392 14.0651 5.77738 14.0651 6.16478C14.0651 6.55218 13.9873 6.93564 13.8364 7.29243C14.4753 7.86202 14.7601 8.63174 14.7601 9.55541C14.7601 12.7959 12.7819 13.5041 10.8807 13.7119C11.227 13.9967 11.5118 14.4201 11.5118 15.2668V17.599C11.5118 17.8068 11.5888 18.0916 12.0737 18.0224C13.9954 17.3971 15.63 16.1052 16.6824 14.3801C17.7347 12.6549 18.1353 10.6103 17.8119 8.61554C17.4884 6.62081 16.4622 4.80754 14.9186 3.50334C13.375 2.19915 11.4158 1.49003 9.3951 1.50411Z"
  })), /* @__PURE__ */ _react2.default.createElement("defs", null, /* @__PURE__ */ _react2.default.createElement("clipPath", {
    id: "clip0_4410_7078"
  }, /* @__PURE__ */ _react2.default.createElement("rect", {
    width: "18.4733",
    height: "18.4733",
    fill: "white",
    transform: "translate(0.158203 0.527344)"
  }))));
}


exports.default = GithubIcon;
