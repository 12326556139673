"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function TimerIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 20 21",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M10.0007 19.4583C5.63398 19.4583 2.08398 15.9083 2.08398 11.5417C2.08398 7.175 5.63398 3.625 10.0007 3.625C14.3673 3.625 17.9173 7.175 17.9173 11.5417C17.9173 15.9083 14.3673 19.4583 10.0007 19.4583ZM10.0007 4.875C6.32565 4.875 3.33398 7.86667 3.33398 11.5417C3.33398 15.2167 6.32565 18.2083 10.0007 18.2083C13.6757 18.2083 16.6673 15.2167 16.6673 11.5417C16.6673 7.86667 13.6757 4.875 10.0007 4.875Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M10 11.9596C9.65833 11.9596 9.375 11.6763 9.375 11.3346V7.16797C9.375 6.8263 9.65833 6.54297 10 6.54297C10.3417 6.54297 10.625 6.8263 10.625 7.16797V11.3346C10.625 11.6763 10.3417 11.9596 10 11.9596Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M12.5 2.79297H7.5C7.15833 2.79297 6.875 2.50964 6.875 2.16797C6.875 1.8263 7.15833 1.54297 7.5 1.54297H12.5C12.8417 1.54297 13.125 1.8263 13.125 2.16797C13.125 2.50964 12.8417 2.79297 12.5 2.79297Z"
  }));
}


exports.default = TimerIcon;
