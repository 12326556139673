"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
var _styledcomponents = require('styled-components');
var _colors = require('../theme/colors');
function ModalErrorCrossIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  const {
    palette: {
      mode
    }
  } = _styledcomponents.useTheme.call(void 0, );
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 74 74",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("g", null, /* @__PURE__ */ _react2.default.createElement("circle", {
    cx: "36.7793",
    cy: "36.7793",
    r: "36.7793",
    fill: _colors.colors[mode].semantic.error.primary,
    fillOpacity: "0.4"
  }), /* @__PURE__ */ _react2.default.createElement("circle", {
    cx: "37.2197",
    cy: "36.7783",
    r: "29.6493",
    fill: _colors.colors[mode].semantic.error.primary
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M27.7275 24.6136L36.5 33.3857L45.227 24.659C45.4198 24.4539 45.652 24.2897 45.9097 24.1765C46.1675 24.0632 46.4455 24.0032 46.727 24C47.3297 24 47.9078 24.2394 48.334 24.6656C48.7602 25.0918 48.9996 25.6698 48.9996 26.2726C49.0049 26.5512 48.9532 26.8279 48.8477 27.0859C48.7422 27.3438 48.5851 27.5774 48.386 27.7725L39.5454 36.4991L48.386 45.3394C48.7606 45.7058 48.9802 46.202 48.9996 46.7256C48.9996 47.3284 48.7602 47.9064 48.334 48.3326C47.9078 48.7588 47.3297 48.9982 46.727 48.9982C46.4373 49.0102 46.1484 48.9619 45.8784 48.8562C45.6084 48.7506 45.3634 48.59 45.1588 48.3846L36.5 39.6125L27.7503 48.3619C27.5582 48.5602 27.3288 48.7186 27.0753 48.8278C26.8217 48.937 26.5491 48.9949 26.273 48.9982C25.6703 48.9982 25.0922 48.7588 24.666 48.3326C24.2398 47.9064 24.0004 47.3284 24.0004 46.7256C23.9951 46.447 24.0468 46.1703 24.1523 45.9123C24.2578 45.6544 24.4149 45.4208 24.614 45.2257L33.4546 36.4991L24.614 27.6588C24.2394 27.2924 24.0198 26.7962 24.0004 26.2726C24.0004 25.6698 24.2398 25.0918 24.666 24.6656C25.0922 24.2394 25.6703 24 26.273 24C26.8185 24.0068 27.3412 24.2273 27.7275 24.6136Z",
    fill: "#FDFDFD"
  })));
}


exports.default = ModalErrorCrossIcon;
