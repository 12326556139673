"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function MoneysIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 20 21",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M12.4077 15.5229H5.08268C4.64934 15.5229 4.241 15.4813 3.87433 15.398C3.68267 15.373 3.44934 15.3063 3.20768 15.2146C2.09934 14.798 0.791016 13.773 0.791016 11.223V6.93128C0.791016 4.20628 2.35768 2.63965 5.08268 2.63965H12.4077C14.7077 2.63965 16.191 3.73962 16.591 5.73962C16.666 6.10629 16.6993 6.49795 16.6993 6.93128V11.223C16.6993 13.9646 15.141 15.5229 12.4077 15.5229ZM5.09099 3.90633C3.04099 3.90633 2.04933 4.89797 2.04933 6.94797V11.2397C2.04933 12.7313 2.57434 13.648 3.64934 14.0563C3.81601 14.1147 3.97435 14.1563 4.12435 14.1813C4.44102 14.248 4.74933 14.2813 5.09099 14.2813H12.416C14.466 14.2813 15.4577 13.2897 15.4577 11.2397V6.94797C15.4577 6.59797 15.4327 6.28963 15.3743 6.0063C15.091 4.58963 14.1243 3.90633 12.416 3.90633H5.09099Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M14.9092 18.0228H7.58422C6.87589 18.0228 6.24255 17.9228 5.70088 17.7145C4.47588 17.2561 3.67587 16.2895 3.40087 14.9061C3.35921 14.6978 3.42589 14.4811 3.57589 14.3395C3.72589 14.1895 3.94256 14.1311 4.15089 14.1811C4.42589 14.2395 4.73422 14.2728 5.08422 14.2728H12.4092C14.4592 14.2728 15.4509 13.2812 15.4509 11.2312V6.93947C15.4509 6.58947 15.4259 6.28113 15.3676 5.9978C15.3259 5.78947 15.3926 5.58114 15.5342 5.43114C15.6842 5.28114 15.8926 5.21446 16.1009 5.26446C18.1009 5.67279 19.2009 7.15614 19.2009 9.43947V13.7312C19.2009 16.4645 17.6426 18.0228 14.9092 18.0228ZM4.93423 15.5228C5.2009 16.0061 5.60089 16.3478 6.15089 16.5478C6.55089 16.6978 7.03421 16.7728 7.59254 16.7728H14.9175C16.9675 16.7728 17.9592 15.7812 17.9592 13.7312V9.43947C17.9592 8.1228 17.5509 7.24781 16.7092 6.78948C16.7092 6.83948 16.7092 6.88947 16.7092 6.93947V11.2312C16.7092 13.9562 15.1425 15.5228 12.4175 15.5228H5.09254C5.03421 15.5228 4.98423 15.5228 4.93423 15.5228Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M8.75079 11.9147C7.19246 11.9147 5.92578 10.648 5.92578 9.08966C5.92578 7.53133 7.19246 6.26465 8.75079 6.26465C10.3091 6.26465 11.5758 7.53133 11.5758 9.08966C11.5758 10.648 10.3091 11.9147 8.75079 11.9147ZM8.75079 7.51465C7.88413 7.51465 7.17578 8.22299 7.17578 9.08966C7.17578 9.95633 7.88413 10.6647 8.75079 10.6647C9.61746 10.6647 10.3258 9.95633 10.3258 9.08966C10.3258 8.22299 9.61746 7.51465 8.75079 7.51465Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M3.98437 11.5485C3.64271 11.5485 3.35938 11.2652 3.35938 10.9235V7.25684C3.35938 6.91517 3.64271 6.63184 3.98437 6.63184C4.32604 6.63184 4.60937 6.91517 4.60937 7.25684V10.9235C4.60937 11.2652 4.33437 11.5485 3.98437 11.5485Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M13.5078 11.5485C13.1661 11.5485 12.8828 11.2652 12.8828 10.9235V7.25684C12.8828 6.91517 13.1661 6.63184 13.5078 6.63184C13.8495 6.63184 14.1328 6.91517 14.1328 7.25684V10.9235C14.1328 11.2652 13.8578 11.5485 13.5078 11.5485Z"
  }));
}


exports.default = MoneysIcon;
