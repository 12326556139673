"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _colors = require('../colors');
var _constants = require('../constants');
var _typography = require('../typography');
const buildAlertVariant = (mode) => ({
  MuiAlert: {
    styleOverrides: {
      standard: {
        background: _colors.colors[mode].background.secondary,
        borderWidth: "1.5px",
        borderStyle: "solid",
        gap: _constants.SPACING.call(void 0, 2),
        ..._typography.buildTypographyVariant.call(void 0, mode).bodyRegular
      },
      standardSuccess: {
        borderColor: _colors.colors[mode].semantic.success.primary,
        ".MuiAlert-icon": {
          color: _colors.colors[mode].semantic.success.darker
        }
      },
      standardError: {
        borderColor: _colors.colors[mode].semantic.error.primary,
        ".MuiAlert-icon": {
          color: _colors.colors[mode].semantic.error.darker
        }
      },
      standardInfo: {
        borderColor: _colors.colors[mode].semantic.informative.darker,
        ".MuiAlert-icon": {
          color: _colors.colors[mode].semantic.informative.darker
        }
      },
      standardWarning: {
        borderColor: _colors.colors[mode].semantic.warning.primary,
        ".MuiAlert-icon": {
          color: _colors.colors[mode].semantic.warning.darker
        }
      }
    }
  }
});


exports.buildAlertVariant = buildAlertVariant;
