"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function EditIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 20 20",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M4.61763 16.2656C4.1093 16.2656 3.6343 16.0906 3.29263 15.7656C2.8593 15.3573 2.65097 14.7406 2.72597 14.074L3.0343 11.374C3.09263 10.8656 3.40097 10.1906 3.7593 9.82398L10.601 2.58232C12.3093 0.773983 14.0926 0.723983 15.901 2.43232C17.7093 4.14065 17.7593 5.92398 16.051 7.73232L9.2093 14.974C8.8593 15.349 8.2093 15.699 7.70096 15.7823L5.01763 16.2406C4.87597 16.249 4.75097 16.2656 4.61763 16.2656ZM13.276 2.42398C12.6343 2.42398 12.076 2.82398 11.5093 3.42398L4.66763 10.674C4.50097 10.849 4.3093 11.2656 4.27597 11.5073L3.96763 14.2073C3.9343 14.4823 4.00097 14.7073 4.15097 14.849C4.30097 14.9906 4.52597 15.0406 4.80097 14.999L7.4843 14.5407C7.72597 14.499 8.12597 14.2823 8.29263 14.1073L15.1343 6.86565C16.1676 5.76565 16.5426 4.74898 15.0343 3.33232C14.3676 2.69065 13.7926 2.42398 13.276 2.42398Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M14.4497 9.12601C14.433 9.12601 14.408 9.12601 14.3914 9.12601C11.7914 8.86768 9.69971 6.89268 9.29971 4.30935C9.24971 3.96768 9.48305 3.65101 9.82471 3.59268C10.1664 3.54268 10.483 3.77601 10.5414 4.11768C10.858 6.13435 12.4914 7.68435 14.5247 7.88435C14.8664 7.91768 15.1164 8.22601 15.083 8.56768C15.0414 8.88435 14.7664 9.12601 14.4497 9.12601Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M17.5 18.959H2.5C2.15833 18.959 1.875 18.6757 1.875 18.334C1.875 17.9923 2.15833 17.709 2.5 17.709H17.5C17.8417 17.709 18.125 17.9923 18.125 18.334C18.125 18.6757 17.8417 18.959 17.5 18.959Z"
  }));
}


exports.default = EditIcon;
