"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _commontypes = require('common-types');
var _reactintl = require('react-intl');
const getIsDelayedWithdraw = (withdrawn) => withdrawn.some((w) => w.withdrawType === _commontypes.WithdrawType.DELAYED && w.amount.amount > BigInt(0));
const getTransactionTypeTitle = (type) => {
  switch (type.type) {
    case _commontypes.TransactionEventTypes.ERC20_APPROVAL:
      if (type.data.amount.amount === BigInt(0)) {
        return _reactintl.defineMessage.call(void 0, {
          id: "92Bey4",
          defaultMessage: [{
            "type": 0,
            "value": "Remove Token Approval"
          }]
        });
      }
      return _reactintl.defineMessage.call(void 0, {
        id: "ipFShi",
        defaultMessage: [{
          "type": 0,
          "value": "Approve Token"
        }]
      });
    case _commontypes.TransactionEventTypes.ERC20_TRANSFER:
      return _reactintl.defineMessage.call(void 0, {
        id: "EqGNLY",
        defaultMessage: [{
          "type": 0,
          "value": "Transfer Token"
        }]
      });
    case _commontypes.TransactionEventTypes.SWAP:
      return _reactintl.defineMessage.call(void 0, {
        id: "+vWRnj",
        defaultMessage: [{
          "type": 0,
          "value": "Swap"
        }]
      });
    case _commontypes.TransactionEventTypes.NATIVE_TRANSFER:
      return _reactintl.defineMessage.call(void 0, {
        id: "BvJC2G",
        defaultMessage: [{
          "type": 0,
          "value": "Transfer Token"
        }]
      });
    case _commontypes.TransactionEventTypes.DCA_WITHDRAW:
      return _reactintl.defineMessage.call(void 0, {
        id: "96hSIS",
        defaultMessage: [{
          "type": 0,
          "value": "Withdraw from Position"
        }]
      });
    case _commontypes.TransactionEventTypes.DCA_MODIFIED:
      return _reactintl.defineMessage.call(void 0, {
        id: "blbha/",
        defaultMessage: [{
          "type": 0,
          "value": "Modified Position"
        }]
      });
    case _commontypes.TransactionEventTypes.DCA_CREATED:
      return _reactintl.defineMessage.call(void 0, {
        id: "pQmQ4Q",
        defaultMessage: [{
          "type": 0,
          "value": "Created DCA Position"
        }]
      });
    case _commontypes.TransactionEventTypes.DCA_PERMISSIONS_MODIFIED:
      return _reactintl.defineMessage.call(void 0, {
        id: "IW0RSP",
        defaultMessage: [{
          "type": 0,
          "value": "Position Permissions modified"
        }]
      });
    case _commontypes.TransactionEventTypes.DCA_TRANSFER:
      return _reactintl.defineMessage.call(void 0, {
        id: "SQ2+Tc",
        defaultMessage: [{
          "type": 0,
          "value": "Position Transfered"
        }]
      });
    case _commontypes.TransactionEventTypes.DCA_TERMINATED:
      return _reactintl.defineMessage.call(void 0, {
        id: "2Okh7Q",
        defaultMessage: [{
          "type": 0,
          "value": "Position Closed"
        }]
      });
    case _commontypes.TransactionEventTypes.EARN_CREATED:
      return _reactintl.defineMessage.call(void 0, {
        id: "iWidFV",
        defaultMessage: [{
          "type": 0,
          "value": "Invested through Earn"
        }]
      });
    case _commontypes.TransactionEventTypes.EARN_INCREASE:
      return _reactintl.defineMessage.call(void 0, {
        id: "KjcPhQ",
        defaultMessage: [{
          "type": 0,
          "value": "Invested through Earn"
        }]
      });
    case _commontypes.TransactionEventTypes.EARN_WITHDRAW:
      if (getIsDelayedWithdraw(type.data.withdrawn)) {
        return _reactintl.defineMessage.call(void 0, {
          id: "OKcug6",
          defaultMessage: [{
            "type": 0,
            "value": "Initiated delayed withdrawal"
          }]
        });
      } else {
        return _reactintl.defineMessage.call(void 0, {
          id: "HruE8E",
          defaultMessage: [{
            "type": 0,
            "value": "Withdrew from Earn"
          }]
        });
      }
    case _commontypes.TransactionEventTypes.EARN_SPECIAL_WITHDRAW:
      return _reactintl.defineMessage.call(void 0, {
        id: "F1DCbX",
        defaultMessage: [{
          "type": 0,
          "value": "Withdrew from Earn"
        }]
      });
    case _commontypes.TransactionEventTypes.EARN_CLAIM_DELAYED_WITHDRAW:
      return _reactintl.defineMessage.call(void 0, {
        id: "rXRsgT",
        defaultMessage: [{
          "type": 0,
          "value": "Claimed delayed withdrawal"
        }]
      });
    default:
      return _reactintl.defineMessage.call(void 0, {
        id: "yL4+MG",
        defaultMessage: [{
          "type": 0,
          "value": "Unknown Transaction"
        }]
      });
  }
};



exports.getIsDelayedWithdraw = getIsDelayedWithdraw; exports.getTransactionTypeTitle = getTransactionTypeTitle;
