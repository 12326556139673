"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _material = require('@mui/material');
var _theme = require('../../theme');
var _mui = require('tss-react/mui');
const PositionProgressBar = _mui.withStyles.call(void 0, (_ref) => {
  let {
    variant = "determinate",
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_material.LinearProgress, _extends({
    variant
  }, props));
}, (_ref2, _ref3) => {
  let {
    palette: {
      mode
    }
  } = _ref2;
  let {
    value
  } = _ref3;
  return {
    bar: {
      background: value === 100 ? _theme.colors[mode].semantic.success.darker : mode === "light" ? `linear-gradient(270deg, ${_theme.colors.light.violet.violet500} 0%, ${_theme.colors.light.violet.violet400} ${value}%)` : _theme.baseColors.violet.violet500
    }
  };
});


exports.PositionProgressBar = PositionProgressBar;
