"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _styledcomponents = require('styled-components'); var _styledcomponents2 = _interopRequireDefault(_styledcomponents);
var _constants = require('../../theme/constants');
var _ = require('../');
const StyledFormContainer = _styledcomponents2.default.call(void 0, _.Grid).attrs({
  flex: "1",
  container: true,
  alignItems: "stretch",
  direction: "row"
})`
  max-width: ${_constants.MAX_FORM_WIDTH};
`;
const StyledNonFormContainer = _styledcomponents2.default.call(void 0, _.Grid).attrs({
  flex: "1",
  container: true,
  alignItems: "stretch",
  direction: "row"
})``;
const ColorCircle = _styledcomponents2.default.div`
  ${(_ref) => {
  let {
    color,
    size,
    theme: {
      spacing
    }
  } = _ref;
  return `
    width: ${spacing(size || 4)};
    height: ${spacing(size || 4)};
    background-color: ${color || "transparent"};
    border-radius: 50%;
    display: inline-block
  `;
}}
`;




exports.ColorCircle = ColorCircle; exports.StyledFormContainer = StyledFormContainer; exports.StyledNonFormContainer = StyledNonFormContainer;
