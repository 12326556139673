"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function SupportIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 24 25",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M8 22.8199C7.72 22.8199 7.42998 22.7499 7.16998 22.6099C6.59998 22.3099 6.25 21.7099 6.25 21.0699V19.65C3.23 19.34 1.25 17.1199 1.25 13.9399V7.93994C1.25 4.49994 3.56 2.18994 7 2.18994H17C20.44 2.18994 22.75 4.49994 22.75 7.93994V13.9399C22.75 17.3799 20.44 19.6899 17 19.6899H13.23L8.96997 22.53C8.67997 22.72 8.34 22.8199 8 22.8199ZM7 3.67993C4.42 3.67993 2.75 5.34993 2.75 7.92993V13.93C2.75 16.51 4.42 18.18 7 18.18C7.41 18.18 7.75 18.52 7.75 18.93V21.06C7.75 21.19 7.83 21.25 7.88 21.28C7.93001 21.31 8.03001 21.34 8.14001 21.27L12.59 18.31C12.71 18.23 12.86 18.18 13.01 18.18H17.01C19.59 18.18 21.26 16.51 21.26 13.93V7.92993C21.26 5.34993 19.59 3.67993 17.01 3.67993H7Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M11.9998 12.61C11.5898 12.61 11.2498 12.27 11.2498 11.86V11.65C11.2498 10.49 12.0998 9.92001 12.4198 9.70001C12.7898 9.45001 12.9098 9.28002 12.9098 9.02002C12.9098 8.52002 12.4998 8.10999 11.9998 8.10999C11.4998 8.10999 11.0898 8.52002 11.0898 9.02002C11.0898 9.43002 10.7498 9.77002 10.3398 9.77002C9.92984 9.77002 9.58984 9.43002 9.58984 9.02002C9.58984 7.69002 10.6698 6.60999 11.9998 6.60999C13.3298 6.60999 14.4098 7.69002 14.4098 9.02002C14.4098 10.16 13.5698 10.73 13.2598 10.94C12.8698 11.2 12.7498 11.37 12.7498 11.65V11.86C12.7498 12.28 12.4098 12.61 11.9998 12.61Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M12 15.1C11.58 15.1 11.25 14.76 11.25 14.35C11.25 13.94 11.59 13.6 12 13.6C12.41 13.6 12.75 13.94 12.75 14.35C12.75 14.76 12.42 15.1 12 15.1Z"
  }));
}


exports.default = SupportIcon;
