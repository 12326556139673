"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
var _colors = require('../theme/colors');
var _styledcomponents = require('styled-components');
function ModalSuccessCheckIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  const {
    palette: {
      mode
    }
  } = _styledcomponents.useTheme.call(void 0, );
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 74 74",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("g", null, /* @__PURE__ */ _react2.default.createElement("circle", {
    cx: "36.7793",
    cy: "36.7793",
    r: "36.7793",
    fill: "#53ED9B",
    fillOpacity: "0.4"
  }), /* @__PURE__ */ _react2.default.createElement("circle", {
    cx: "37.2197",
    cy: "36.7783",
    r: "29.6493",
    fill: _colors.colors[mode].semantic.success.primary
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M48.3243 28.8398L32.45 44.7142L25.2344 37.4986",
    stroke: "#F6F6F9",
    strokeWidth: "2.88624",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    fill: "none"
  })));
}


exports.default = ModalSuccessCheckIcon;
