"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _colors = require('../colors');
const buildDividerVariant = (mode) => ({
  MuiDivider: {
    styleOverrides: {
      root: {
        borderColor: _colors.colors[mode].border.border2,
        borderWidth: "1px"
      }
    }
  }
});


exports.buildDividerVariant = buildDividerVariant;
