"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function CloseCircleIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 25 24",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M12.1992 22.75C6.26922 22.75 1.44922 17.93 1.44922 12C1.44922 6.07 6.26922 1.25 12.1992 1.25C18.1292 1.25 22.9492 6.07 22.9492 12C22.9492 17.93 18.1292 22.75 12.1992 22.75ZM12.1992 2.75C7.09922 2.75 2.94922 6.9 2.94922 12C2.94922 17.1 7.09922 21.25 12.1992 21.25C17.2992 21.25 21.4492 17.1 21.4492 12C21.4492 6.9 17.2992 2.75 12.1992 2.75Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M9.36859 15.5794C9.17859 15.5794 8.98859 15.5094 8.83859 15.3594C8.54859 15.0694 8.54859 14.5894 8.83859 14.2994L14.4986 8.63938C14.7886 8.34938 15.2686 8.34938 15.5586 8.63938C15.8486 8.92937 15.8486 9.40937 15.5586 9.69937L9.89859 15.3594C9.75859 15.5094 9.55859 15.5794 9.36859 15.5794Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M15.0286 15.5794C14.8386 15.5794 14.6486 15.5094 14.4986 15.3594L8.83859 9.69937C8.54859 9.40937 8.54859 8.92937 8.83859 8.63938C9.12859 8.34938 9.60859 8.34938 9.89859 8.63938L15.5586 14.2994C15.8486 14.5894 15.8486 15.0694 15.5586 15.3594C15.4086 15.5094 15.2186 15.5794 15.0286 15.5794Z"
  }));
}


exports.default = CloseCircleIcon;
