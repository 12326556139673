"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function DashboardIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 24 25",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M19.77 11.75H15.73C13.72 11.75 12.75 10.77 12.75 8.77V4.73C12.75 2.72 13.73 1.75 15.73 1.75H19.77C21.78 1.75 22.75 2.73 22.75 4.73V8.77C22.75 10.77 21.77 11.75 19.77 11.75ZM15.73 3.25C14.55 3.25 14.25 3.55 14.25 4.73V8.77C14.25 9.95 14.55 10.25 15.73 10.25H19.77C20.95 10.25 21.25 9.95 21.25 8.77V4.73C21.25 3.55 20.95 3.25 19.77 3.25H15.73Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M8.27 11.75H4.23C2.22 11.75 1.25 10.86 1.25 9.02V4.48C1.25 2.64 2.23 1.75 4.23 1.75H8.27C10.28 1.75 11.25 2.64 11.25 4.48V9.01C11.25 10.86 10.27 11.75 8.27 11.75ZM4.23 3.25C2.89 3.25 2.75 3.63 2.75 4.48V9.01C2.75 9.87 2.89 10.24 4.23 10.24H8.27C9.61 10.24 9.75 9.86 9.75 9.01V4.48C9.75 3.62 9.61 3.25 8.27 3.25H4.23Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M8.27 23.25H4.23C2.22 23.25 1.25 22.27 1.25 20.27V16.23C1.25 14.22 2.23 13.25 4.23 13.25H8.27C10.28 13.25 11.25 14.23 11.25 16.23V20.27C11.25 22.27 10.27 23.25 8.27 23.25ZM4.23 14.75C3.05 14.75 2.75 15.05 2.75 16.23V20.27C2.75 21.45 3.05 21.75 4.23 21.75H8.27C9.45 21.75 9.75 21.45 9.75 20.27V16.23C9.75 15.05 9.45 14.75 8.27 14.75H4.23Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M21 16.75H15C14.59 16.75 14.25 16.41 14.25 16C14.25 15.59 14.59 15.25 15 15.25H21C21.41 15.25 21.75 15.59 21.75 16C21.75 16.41 21.41 16.75 21 16.75Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M21 20.75H15C14.59 20.75 14.25 20.41 14.25 20C14.25 19.59 14.59 19.25 15 19.25H21C21.41 19.25 21.75 19.59 21.75 20C21.75 20.41 21.41 20.75 21 20.75Z"
  }));
}


exports.default = DashboardIcon;
