"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _material = require('@mui/material');
var _styledcomponents = require('styled-components'); var _styledcomponents2 = _interopRequireDefault(_styledcomponents);
var _theme = require('../../theme');
var _icons = require('../../icons');
const MoreVertButtonIcon = _styledcomponents2.default.call(void 0, (_ref) => {
  let {
    $isActive,
    fontSize = "medium",
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_material.IconButton, props, /* @__PURE__ */ _react2.default.createElement(_icons.MoreVertIcon, {
    fontSize
  }));
})`
  ${(_ref2) => {
  let {
    theme: {
      palette,
      spacing
    },
    $isActive
  } = _ref2;
  return `
  color: ${_theme.colors[palette.mode].typography.typo3};
  background: ${_theme.colors[palette.mode].background.secondary};
  border-radius: 50%;
  padding: ${spacing(2)};
  border: 1px solid ${_theme.colors[palette.mode].border.border1};
  :hover {
    background: ${_theme.colors[palette.mode].background.secondary};
  }
  ${$isActive && `
  background: ${_theme.colors[palette.mode].background.emphasis};
  `}
`;
}}
`;


exports.MoreVertButtonIcon = MoreVertButtonIcon;
