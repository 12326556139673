"use strict";Object.defineProperty(exports, "__esModule", {value: true});var TokenType = /* @__PURE__ */ ((TokenType2) => {
  TokenType2["BASE"] = "BASE";
  TokenType2["NATIVE"] = "NATIVE";
  TokenType2["WRAPPED_PROTOCOL_TOKEN"] = "WRAPPED_PROTOCOL_TOKEN";
  TokenType2["YIELD_BEARING_SHARE"] = "YIELD_BEARING_SHARE";
  TokenType2["ERC20_TOKEN"] = "ERC20_TOKEN";
  TokenType2["ERC721_TOKEN"] = "ERC721_TOKEN";
  TokenType2["ASSET"] = "asset";
  TokenType2["FARM"] = "farm";
  return TokenType2;
})(TokenType || {});


exports.TokenType = TokenType;
