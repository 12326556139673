"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _styledcomponents = require('styled-components');
var _styles = require('@mui/material/styles');
var _CssBaseline = require('@mui/material/CssBaseline'); var _CssBaseline2 = _interopRequireDefault(_CssBaseline);
var _theme = require('../../theme');
const ThemeProvider = (_ref) => {
  let {
    mode,
    children
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_styles.ThemeProvider, {
    theme: mode === "dark" ? _theme.darkTheme : _theme.lightTheme
  }, /* @__PURE__ */ _react2.default.createElement(_styledcomponents.ThemeProvider, {
    theme: mode === "dark" ? _theme.darkTheme : _theme.lightTheme
  }, /* @__PURE__ */ _react2.default.createElement(_CssBaseline2.default, null), children));
};


exports.ThemeProvider = ThemeProvider;
