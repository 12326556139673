"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function WalletCheckIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 25 25",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M5.67578 24.0586C4.01578 24.0586 2.45578 23.1786 1.61578 21.7486C1.16578 21.0286 0.925781 20.1786 0.925781 19.3086C0.925781 16.6886 3.05578 14.5586 5.67578 14.5586C8.29578 14.5586 10.4258 16.6886 10.4258 19.3086C10.4258 20.1786 10.1858 21.0286 9.73578 21.7586C8.89578 23.1786 7.33578 24.0586 5.67578 24.0586ZM5.67578 16.0586C3.88578 16.0586 2.42578 17.5186 2.42578 19.3086C2.42578 19.8986 2.58578 20.4786 2.89578 20.9786C3.48578 21.9786 4.52578 22.5586 5.67578 22.5586C6.82578 22.5586 7.86578 21.9686 8.45578 20.9886C8.76578 20.4786 8.92578 19.9086 8.92578 19.3086C8.92578 17.5186 7.46578 16.0586 5.67578 16.0586Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M5.10469 21.0482C4.91469 21.0482 4.72469 20.9782 4.57469 20.8282L3.58469 19.8382C3.29469 19.5482 3.29469 19.0681 3.58469 18.7781C3.87469 18.4881 4.35469 18.4881 4.64469 18.7781L5.12469 19.2582L6.72469 17.7781C7.02469 17.4981 7.50469 17.5182 7.78469 17.8182C8.06469 18.1182 8.04469 18.5982 7.74469 18.8782L5.61469 20.8481C5.46469 20.9781 5.28469 21.0482 5.10469 21.0482Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M6.86583 8.11805C6.62583 8.11805 6.40583 8.00806 6.25583 7.80806C6.08583 7.57806 6.06583 7.27803 6.19583 7.02803C6.36583 6.68803 6.60583 6.35806 6.91583 6.05806L10.1658 2.79805C11.8258 1.14805 14.5258 1.14805 16.1858 2.79805L17.9358 4.57808C18.6758 5.30808 19.1258 6.28808 19.1758 7.32808C19.1858 7.55808 19.0958 7.77805 18.9258 7.92805C18.7558 8.07805 18.5258 8.14804 18.3058 8.10804C18.1058 8.07804 17.8958 8.06807 17.6758 8.06807H7.67583C7.43583 8.06807 7.20583 8.08805 6.97583 8.11805C6.94583 8.11805 6.90583 8.11805 6.86583 8.11805ZM8.53583 6.55806H17.4958C17.3658 6.21806 17.1558 5.90806 16.8758 5.62806L15.1158 3.84803C14.0458 2.78803 12.2958 2.78803 11.2158 3.84803L8.53583 6.55806Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M17.6763 23.0586H8.30625C7.98625 23.0586 7.70625 22.8586 7.59625 22.5686C7.48625 22.2686 7.57625 21.9386 7.81625 21.7386C8.05625 21.5386 8.27625 21.2786 8.43625 20.9986C8.75625 20.4886 8.91625 19.9086 8.91625 19.3186C8.91625 17.5286 7.45625 16.0686 5.66625 16.0686C4.73625 16.0686 3.84625 16.4686 3.22625 17.1786C3.01625 17.4086 2.68625 17.4986 2.39625 17.3886C2.10625 17.2786 1.90625 16.9986 1.90625 16.6886V12.3086C1.90625 9.22859 3.80625 6.9986 6.75625 6.6286C7.02625 6.5886 7.33625 6.55859 7.65625 6.55859H17.6562C17.8962 6.55859 18.2062 6.56859 18.5262 6.61859C21.4762 6.95859 23.4063 9.19859 23.4063 12.3086V17.3086C23.4263 20.7486 21.1163 23.0586 17.6763 23.0586ZM9.85625 21.5586H17.6763C20.2563 21.5586 21.9263 19.8886 21.9263 17.3086V12.3086C21.9263 9.96859 20.5562 8.35857 18.3363 8.09857C18.0963 8.05857 17.8863 8.05859 17.6763 8.05859H7.67625C7.43625 8.05859 7.20625 8.07858 6.97625 8.10858C4.77625 8.38858 3.42625 9.98859 3.42625 12.3086V15.1286C4.10625 14.7586 4.88625 14.5586 5.67625 14.5586C8.29625 14.5586 10.4263 16.6886 10.4263 19.3086C10.4263 20.0986 10.2263 20.8786 9.85625 21.5586Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M22.6758 17.5586H19.6758C18.1558 17.5586 16.9258 16.3286 16.9258 14.8086C16.9258 13.2886 18.1558 12.0586 19.6758 12.0586H22.6758C23.0858 12.0586 23.4258 12.3986 23.4258 12.8086C23.4258 13.2186 23.0858 13.5586 22.6758 13.5586H19.6758C18.9858 13.5586 18.4258 14.1186 18.4258 14.8086C18.4258 15.4986 18.9858 16.0586 19.6758 16.0586H22.6758C23.0858 16.0586 23.4258 16.3986 23.4258 16.8086C23.4258 17.2186 23.0858 17.5586 22.6758 17.5586Z"
  }));
}


exports.default = WalletCheckIcon;
