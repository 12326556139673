"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _colors = require('../colors');
const buildPaperVariant = (mode) => ({
  MuiPaper: {
    styleOverrides: {
      outlined: {
        outlineColor: _colors.colors[mode].border.border1,
        outlineStyle: "solid"
      }
    }
  }
});


exports.buildPaperVariant = buildPaperVariant;
