"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function MovingStarIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 24 25",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M9.4802 21.19C8.9802 21.19 8.6302 21.02 8.4202 20.86C8.0202 20.57 7.40019 19.82 7.79019 18.14L8.36017 15.68C8.41017 15.47 8.30018 15.09 8.15018 14.93L6.16019 12.94C5.11019 11.89 5.20018 10.98 5.34018 10.53C5.48018 10.08 5.94018 9.28999 7.40018 9.03999L9.9502 8.62C10.1402 8.58999 10.4502 8.36 10.5302 8.19L11.9402 5.37C12.6202 4.01 13.5202 3.81 14.0002 3.81C14.4802 3.81 15.3802 4.01 16.0602 5.37L17.4702 8.19C17.5602 8.36 17.8602 8.58999 18.0502 8.62L20.6002 9.03999C22.0602 9.27999 22.5202 10.08 22.6602 10.53C22.8002 10.98 22.8902 11.89 21.8402 12.94L19.8502 14.93C19.7002 15.08 19.5902 15.47 19.6402 15.68L20.2102 18.14C20.6002 19.83 19.9802 20.57 19.5802 20.86C19.1802 21.15 18.2902 21.52 16.7902 20.63L14.4002 19.21C14.2002 19.09 13.7802 19.09 13.5802 19.21L11.1902 20.63C10.5002 21.05 9.9202 21.19 9.4802 21.19ZM14.0002 5.31C13.8402 5.31 13.5502 5.49999 13.2802 6.03999L11.8702 8.86C11.5702 9.47 10.8602 9.99001 10.1902 10.1L7.6402 10.52C7.0702 10.62 6.8102 10.83 6.7602 10.99C6.7102 11.15 6.80018 11.47 7.21018 11.88L9.2002 13.87C9.7102 14.38 9.98019 15.31 9.82019 16.01L9.25019 18.47C9.08019 19.18 9.19017 19.56 9.30017 19.65C9.41017 19.73 9.81018 19.72 10.4302 19.34L12.8302 17.92C13.5002 17.52 14.5102 17.52 15.1702 17.92L17.5602 19.34C18.1902 19.71 18.5902 19.73 18.7002 19.65C18.8102 19.57 18.9202 19.19 18.7502 18.47L18.1802 16.01C18.0202 15.3 18.2802 14.38 18.8002 13.87L20.7902 11.88C21.2002 11.47 21.2902 11.14 21.2402 10.99C21.1902 10.84 20.9302 10.62 20.3602 10.52L17.8102 10.1C17.1402 9.99001 16.4302 9.47 16.1302 8.86L14.7202 6.03999C14.4502 5.49999 14.1602 5.31 14.0002 5.31Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M8 6.25H2C1.59 6.25 1.25 5.91 1.25 5.5C1.25 5.09 1.59 4.75 2 4.75H8C8.41 4.75 8.75 5.09 8.75 5.5C8.75 5.91 8.41 6.25 8 6.25Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M5 20.25H2C1.59 20.25 1.25 19.91 1.25 19.5C1.25 19.09 1.59 18.75 2 18.75H5C5.41 18.75 5.75 19.09 5.75 19.5C5.75 19.91 5.41 20.25 5 20.25Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M3 13.25H2C1.59 13.25 1.25 12.91 1.25 12.5C1.25 12.09 1.59 11.75 2 11.75H3C3.41 11.75 3.75 12.09 3.75 12.5C3.75 12.91 3.41 13.25 3 13.25Z"
  }));
}


exports.default = MovingStarIcon;
