"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function DcaInvestIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 24 25",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M22 23.25H5C2.93 23.25 1.25 21.57 1.25 19.5V2.5C1.25 2.09 1.59 1.75 2 1.75C2.41 1.75 2.75 2.09 2.75 2.5V19.5C2.75 20.74 3.76 21.75 5 21.75H22C22.41 21.75 22.75 22.09 22.75 22.5C22.75 22.91 22.41 23.25 22 23.25Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M4.99982 18.25C4.82982 18.25 4.64982 18.19 4.50982 18.07C4.19982 17.8 4.15982 17.33 4.42982 17.01L9.01982 11.65C9.51982 11.07 10.2398 10.72 10.9998 10.69C11.7598 10.67 12.5098 10.95 13.0498 11.49L13.9998 12.44C14.2498 12.69 14.5798 12.81 14.9298 12.81C15.2798 12.8 15.5998 12.64 15.8298 12.37L20.4198 7.01002C20.6898 6.70002 21.1598 6.66 21.4798 6.93C21.7898 7.2 21.8298 7.67 21.5598 7.99L16.9698 13.35C16.4698 13.93 15.7498 14.28 14.9898 14.31C14.2298 14.33 13.4798 14.05 12.9398 13.51L11.9998 12.56C11.7498 12.31 11.4198 12.18 11.0698 12.19C10.7198 12.2 10.3998 12.36 10.1698 12.63L5.57982 17.99C5.41982 18.16 5.20982 18.25 4.99982 18.25Z"
  }));
}


exports.default = DcaInvestIcon;
