"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _containerbox = require('../container-box');
var _svgicon = require('../svgicon');
var _theme = require('../../theme');
var _styledcomponents = require('styled-components'); var _styledcomponents2 = _interopRequireDefault(_styledcomponents);
const NUMBER_SIZES_TO_HEIGHT = {
  small: 18,
  medium: 24,
  large: 32
};
const StyledContainerBox = _styledcomponents2.default.call(void 0, _containerbox.ContainerBox)`
  ${(_ref) => {
  let {
    size
  } = _ref;
  return `height: ${NUMBER_SIZES_TO_HEIGHT[size || "medium"]}px;`;
}}
  display: inline-flex;
`;
function CircleIcon(_ref2) {
  let {
    size,
    ...props
  } = _ref2;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 100 100",
    style: size ? {
      fontSize: size
    } : {}
  }, props, {
    sx: {
      color: (_ref3) => {
        let {
          palette: {
            mode
          }
        } = _ref3;
        return _theme.colors[mode].typography.typo5;
      }
    }
  }), /* @__PURE__ */ _react2.default.createElement("circle", {
    cx: "50",
    cy: "50",
    r: "50"
  }));
}
const NUMBER_SIZES_TO_REM = {
  small: 0.625,
  medium: 0.75,
  large: 1
};
const CIRCLES_TO_USE = 4;
const circles = Array.from(Array(CIRCLES_TO_USE).keys());
const HiddenNumber = (_ref4) => {
  let {
    size = "medium",
    justifyContent = "center"
  } = _ref4;
  return /* @__PURE__ */ _react2.default.createElement(StyledContainerBox, {
    gap: 1,
    alignItems: "center",
    justifyContent,
    size
  }, circles.map((key) => /* @__PURE__ */ _react2.default.createElement(CircleIcon, {
    size: `${NUMBER_SIZES_TO_REM[size]}rem`,
    key
  })));
};



exports.HiddenNumber = HiddenNumber; exports.default = CircleIcon;
