"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function WalletIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 20 20",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    id: "Vector",
    d: "M10.834 8.125H5.83398C5.49232 8.125 5.20898 7.84167 5.20898 7.5C5.20898 7.15833 5.49232 6.875 5.83398 6.875H10.834C11.1757 6.875 11.459 7.15833 11.459 7.5C11.459 7.84167 11.1757 8.125 10.834 8.125Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    id: "Vector_2",
    d: "M15.8672 12.3327C14.6089 12.3327 13.5422 11.3993 13.4422 10.1993C13.3756 9.50766 13.6256 8.83268 14.1256 8.34101C14.5423 7.90768 15.1339 7.66602 15.7589 7.66602H17.5006C18.3256 7.69102 18.9589 8.34098 18.9589 9.14098V10.8577C18.9589 11.6577 18.3256 12.3077 17.5256 12.3327H15.8672ZM17.4756 8.91602H15.7672C15.4756 8.91602 15.2089 9.02434 15.0173 9.22434C14.7756 9.45768 14.6589 9.77433 14.6922 10.091C14.7339 10.641 15.2672 11.0827 15.8672 11.0827H17.5006C17.6089 11.0827 17.7089 10.9827 17.7089 10.8577V9.14098C17.7089 9.01598 17.6089 8.92435 17.4756 8.91602Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    id: "Vector_3",
    d: "M13.3327 17.7077H5.83268C2.96602 17.7077 1.04102 15.7827 1.04102 12.916V7.08268C1.04102 4.51602 2.62433 2.65769 5.08266 2.34936C5.30766 2.31602 5.56602 2.29102 5.83268 2.29102H13.3327C13.5327 2.29102 13.791 2.29935 14.0577 2.34101C16.516 2.62435 18.1243 4.49102 18.1243 7.08268V8.29103C18.1243 8.63269 17.841 8.91603 17.4993 8.91603H15.766C15.4743 8.91603 15.2077 9.02435 15.016 9.22435L15.0077 9.2327C14.7744 9.4577 14.666 9.766 14.691 10.0827C14.7327 10.6327 15.266 11.0743 15.866 11.0743H17.4993C17.841 11.0743 18.1243 11.3577 18.1243 11.6993V12.9077C18.1243 15.7827 16.1993 17.7077 13.3327 17.7077ZM5.83268 3.54102C5.63268 3.54102 5.44101 3.55767 5.24934 3.58267C3.41601 3.81601 2.29102 5.14935 2.29102 7.08268V12.916C2.29102 15.066 3.68268 16.4577 5.83268 16.4577H13.3327C15.4827 16.4577 16.8743 15.066 16.8743 12.916V12.3327H15.866C14.6077 12.3327 13.541 11.3993 13.441 10.1993C13.3744 9.51601 13.6244 8.8327 14.1244 8.34937C14.5577 7.9077 15.141 7.66603 15.766 7.66603H16.8743V7.08268C16.8743 5.13268 15.7327 3.791 13.8827 3.57433C13.6827 3.541 13.5077 3.54102 13.3327 3.54102H5.83268Z"
  }));
}


exports.default = WalletIcon;
