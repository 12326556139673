"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } }var _Button = require('@mui/material/Button'); var _Button2 = _interopRequireDefault(_Button);
var _styledcomponents = require('styled-components'); var _styledcomponents2 = _interopRequireDefault(_styledcomponents);
const Button = _styledcomponents2.default.call(void 0, _Button2.default)`
  ${(_ref) => {
  let {
    theme: {
      spacing
    },
    maxWidth
  } = _ref;
  return `
  max-width: ${_nullishCoalesce(maxWidth, () => ( spacing(87.5)))};
`;
}}
`;


exports.Button = Button;
