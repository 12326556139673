"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function ToggleHorizontalIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 21 21",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M6.10167 11.3274C6.26 11.3274 6.41836 11.3858 6.54336 11.5108C6.78503 11.7525 6.78503 12.1525 6.54336 12.3941L3.88502 15.0525L6.54336 17.7108C6.78503 17.9525 6.78503 18.3525 6.54336 18.5941C6.30169 18.8358 5.90169 18.8358 5.66003 18.5941L2.56 15.4941C2.44334 15.3774 2.37669 15.2191 2.37669 15.0525C2.37669 14.8858 2.44334 14.7274 2.56 14.6108L5.66003 11.5108C5.78503 11.3941 5.94334 11.3274 6.10167 11.3274Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M3 14.4258L18 14.4258C18.3417 14.4258 18.625 14.7091 18.625 15.0508C18.625 15.3924 18.3417 15.6758 18 15.6758L3 15.6758C2.65833 15.6758 2.375 15.3924 2.375 15.0508C2.375 14.7091 2.65833 14.4258 3 14.4258Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M14.9 2.53453C15.0584 2.53453 15.2167 2.59284 15.3417 2.71784L18.4417 5.81786C18.5584 5.93453 18.625 6.09284 18.625 6.25951C18.625 6.42617 18.5584 6.58453 18.4417 6.7012L15.3417 9.80117C15.1 10.0428 14.7 10.0428 14.4583 9.80117C14.2167 9.5595 14.2167 9.15951 14.4583 8.91784L17.1167 6.2595L14.4583 3.60122C14.2167 3.35955 14.2167 2.95951 14.4583 2.71784C14.575 2.59284 14.7334 2.53453 14.9 2.53453Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M3 5.64453L18 5.64453C18.3417 5.64453 18.625 5.92786 18.625 6.26953C18.625 6.6112 18.3417 6.89453 18 6.89453L3 6.89453C2.65833 6.89453 2.375 6.6112 2.375 6.26953C2.375 5.92786 2.65833 5.64453 3 5.64453Z"
  }));
}


exports.default = ToggleHorizontalIcon;
