"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _colors = require('../colors');
const buildAppBarVariant = (mode) => ({
  MuiAppBar: {
    styleOverrides: {
      root: {
        boxShadow: _colors.colors[mode].dropShadow.dropShadow100
      }
    }
  }
});


exports.buildAppBarVariant = buildAppBarVariant;
