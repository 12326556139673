"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function SunIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 24 25",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M12 19.75C8 19.75 4.75 16.5 4.75 12.5C4.75 8.5 8 5.25 12 5.25C16 5.25 19.25 8.5 19.25 12.5C19.25 16.5 16 19.75 12 19.75ZM12 6.75C8.83 6.75 6.25 9.33 6.25 12.5C6.25 15.67 8.83 18.25 12 18.25C15.17 18.25 17.75 15.67 17.75 12.5C17.75 9.33 15.17 6.75 12 6.75Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M12 23.46C11.45 23.46 11 23.05 11 22.5V22.42C11 21.87 11.45 21.42 12 21.42C12.55 21.42 13 21.87 13 22.42C13 22.97 12.55 23.46 12 23.46ZM19.14 20.64C18.88 20.64 18.63 20.54 18.43 20.35L18.3 20.22C17.91 19.83 17.91 19.2 18.3 18.81C18.69 18.42 19.32 18.42 19.71 18.81L19.84 18.94C20.23 19.33 20.23 19.96 19.84 20.35C19.65 20.54 19.4 20.64 19.14 20.64ZM4.86 20.64C4.6 20.64 4.35 20.54 4.15 20.35C3.76 19.96 3.76 19.33 4.15 18.94L4.28 18.81C4.67 18.42 5.3 18.42 5.69 18.81C6.08 19.2 6.08 19.83 5.69 20.22L5.56 20.35C5.37 20.54 5.11 20.64 4.86 20.64ZM22 13.5H21.92C21.37 13.5 20.92 13.05 20.92 12.5C20.92 11.95 21.37 11.5 21.92 11.5C22.47 11.5 22.96 11.95 22.96 12.5C22.96 13.05 22.55 13.5 22 13.5ZM2.08 13.5H2C1.45 13.5 1 13.05 1 12.5C1 11.95 1.45 11.5 2 11.5C2.55 11.5 3.04 11.95 3.04 12.5C3.04 13.05 2.63 13.5 2.08 13.5ZM19.01 6.49C18.75 6.49 18.5 6.39 18.3 6.2C17.91 5.81 17.91 5.18 18.3 4.79L18.43 4.66C18.82 4.27 19.45 4.27 19.84 4.66C20.23 5.05 20.23 5.68 19.84 6.07L19.71 6.2C19.52 6.39 19.27 6.49 19.01 6.49ZM4.99 6.49C4.73 6.49 4.48 6.39 4.28 6.2L4.15 6.06C3.76 5.67 3.76 5.04 4.15 4.65C4.54 4.26 5.17 4.26 5.56 4.65L5.69 4.78C6.08 5.17 6.08 5.8 5.69 6.19C5.5 6.39 5.24 6.49 4.99 6.49ZM12 3.54C11.45 3.54 11 3.13 11 2.58V2.5C11 1.95 11.45 1.5 12 1.5C12.55 1.5 13 1.95 13 2.5C13 3.05 12.55 3.54 12 3.54Z"
  }));
}


exports.default = SunIcon;
