"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function TickCircleIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 25 24",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M12.3672 22.75C6.43719 22.75 1.61719 17.93 1.61719 12C1.61719 6.07 6.43719 1.25 12.3672 1.25C18.2972 1.25 23.1172 6.07 23.1172 12C23.1172 17.93 18.2972 22.75 12.3672 22.75ZM12.3672 2.75C7.26719 2.75 3.11719 6.9 3.11719 12C3.11719 17.1 7.26719 21.25 12.3672 21.25C17.4672 21.25 21.6172 17.1 21.6172 12C21.6172 6.9 17.4672 2.75 12.3672 2.75Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M10.9466 15.5796C10.7466 15.5796 10.5566 15.4996 10.4166 15.3596L7.58664 12.5296C7.29664 12.2396 7.29664 11.7596 7.58664 11.4696C7.87664 11.1796 8.35664 11.1796 8.64664 11.4696L10.9466 13.7696L16.0866 8.62961C16.3766 8.33961 16.8566 8.33961 17.1466 8.62961C17.4366 8.91961 17.4366 9.39961 17.1466 9.68961L11.4766 15.3596C11.3366 15.4996 11.1466 15.5796 10.9466 15.5796Z"
  }));
}


exports.default = TickCircleIcon;
