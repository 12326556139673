"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function ProfileAddIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 25 25",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M19.4766 21.0703H15.4766C15.0666 21.0703 14.7266 20.7303 14.7266 20.3203C14.7266 19.9103 15.0666 19.5703 15.4766 19.5703H19.4766C19.8866 19.5703 20.2266 19.9103 20.2266 20.3203C20.2266 20.7303 19.8866 21.0703 19.4766 21.0703Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M17.4766 23.0703C17.0666 23.0703 16.7266 22.7303 16.7266 22.3203V18.3203C16.7266 17.9103 17.0666 17.5703 17.4766 17.5703C17.8866 17.5703 18.2266 17.9103 18.2266 18.3203V22.3203C18.2266 22.7303 17.8866 23.0703 17.4766 23.0703Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M13.1372 12.4403C13.1072 12.4403 13.0872 12.4403 13.0572 12.4403C13.0072 12.4303 12.9372 12.4303 12.8772 12.4403C9.97725 12.3503 7.78725 10.0703 7.78725 7.26031C7.77725 5.88031 8.31725 4.58031 9.29725 3.60031C10.2772 2.62031 11.5772 2.07031 12.9672 2.07031C15.8272 2.07031 18.1572 4.40031 18.1572 7.26031C18.1572 10.0703 15.9672 12.3403 13.1672 12.4403C13.1572 12.4403 13.1472 12.4403 13.1372 12.4403ZM12.9672 3.57031C11.9772 3.57031 11.0572 3.96031 10.3572 4.65031C9.66725 5.35031 9.28725 6.27031 9.28725 7.25031C9.28725 9.25031 10.8472 10.8703 12.8372 10.9303C12.8972 10.9203 13.0272 10.9203 13.1572 10.9303C15.1272 10.8403 16.6572 9.23031 16.6572 7.25031C16.6572 5.23031 14.9972 3.57031 12.9672 3.57031Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M12.9667 23.38C10.9267 23.38 8.99672 22.85 7.53672 21.87C6.14672 20.94 5.38672 19.67 5.38672 18.3C5.38672 16.93 6.15672 15.67 7.53672 14.75C10.5267 12.75 15.3867 12.75 18.3767 14.75C18.7167 14.98 18.8167 15.45 18.5867 15.79C18.3567 16.14 17.8867 16.23 17.5467 16C15.0567 14.34 10.8567 14.34 8.36672 16C7.40672 16.64 6.88672 17.45 6.88672 18.3C6.88672 19.15 7.40672 19.98 8.36672 20.62C9.57672 21.43 11.2067 21.87 12.9567 21.87C13.3667 21.87 13.7067 22.21 13.7067 22.62C13.7067 23.03 13.3767 23.38 12.9667 23.38Z"
  }));
}


exports.default = ProfileAddIcon;
