"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _styledcomponents = require('styled-components'); var _styledcomponents2 = _interopRequireDefault(_styledcomponents);
var _ = require('../');
var _theme = require('../../theme');
const StyledBackgroundPaper = _styledcomponents2.default.call(void 0, _.Paper)`
  ${(_ref) => {
  let {
    theme: {
      palette: {
        mode
      },
      spacing,
      space
    }
  } = _ref;
  return `
    background-color: ${_theme.colors[mode].background.quartery};
    border-radius: ${spacing(4)};
    padding: ${space.s06};
  `;
}}
`;
const BackgroundPaper = (_ref2) => {
  let {
    children,
    ...otherProps
  } = _ref2;
  return /* @__PURE__ */ _react2.default.createElement(StyledBackgroundPaper, otherProps, children);
};


exports.BackgroundPaper = BackgroundPaper;
