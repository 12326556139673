"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
var _styledcomponents = require('styled-components');
var _theme = require('../theme');
function ErrorCircleIcon(_ref) {
  let {
    size = "162px",
    ...props
  } = _ref;
  const {
    palette: {
      mode
    }
  } = _styledcomponents.useTheme.call(void 0, );
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 131 131",
    style: {
      fontSize: size
    }
  }, props), /* @__PURE__ */ _react2.default.createElement("circle", {
    opacity: "0.4",
    cx: "65.2344",
    cy: "65.1855",
    r: "65.1855",
    fill: _theme.colors[mode].semantic.error.light
  }), /* @__PURE__ */ _react2.default.createElement("circle", {
    cx: "65.2344",
    cy: "65.1855",
    r: "52.5488",
    fill: _theme.colors[mode].semantic.error.primary
  }));
}


exports.default = ErrorCircleIcon;
