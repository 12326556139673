"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _isUndefined = require('lodash/isUndefined'); var _isUndefined2 = _interopRequireDefault(_isUndefined);
var _viem = require('viem');
var _decimaljslight = require('decimal.js-light'); var _decimaljslight2 = _interopRequireDefault(_decimaljslight);
var _toformat = require('toformat'); var _toformat2 = _interopRequireDefault(_toformat);
var _jsbi = require('jsbi'); var _jsbi2 = _interopRequireDefault(_jsbi);
const Decimal = _toformat2.default.call(void 0, _decimaljslight2.default);
const toSignificant = function(currency, decimals) {
  let significantDigits = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : 6;
  let format = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : {
    groupSeparator: ""
  };
  const decimalScale = _jsbi2.default.exponentiate(_jsbi2.default.BigInt(10), _jsbi2.default.BigInt(decimals));
  const numerator = _jsbi2.default.BigInt(currency);
  const denominator = _jsbi2.default.BigInt(decimalScale);
  Decimal.set({
    precision: 20,
    rounding: Decimal.ROUND_UP
  });
  const baseNumber = new Decimal(numerator.toString()).div(denominator.toString()).toFixed(0);
  const nonDecimalPlaces = baseNumber === "0" ? 0 : baseNumber.toString().length;
  Decimal.set({
    precision: nonDecimalPlaces + significantDigits + 1,
    rounding: Decimal.ROUND_UP
  });
  const quotient = new Decimal(numerator.toString()).div(denominator.toString()).toSignificantDigits(nonDecimalPlaces + significantDigits);
  return quotient.toFormat(quotient.decimalPlaces(), format);
};
const toSignificantFromBigDecimal = function(currency) {
  let significantDigits = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 6;
  let threshold = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : 1e-4;
  let format = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : {
    groupSeparator: ""
  };
  if (_isUndefined2.default.call(void 0, currency) || isNaN(Number(currency))) {
    return "-";
  }
  const quotient = new Decimal(currency).toSignificantDigits(significantDigits);
  if (quotient.lessThan(threshold)) {
    return `<${threshold}`;
  }
  return quotient.toFormat(quotient.decimalPlaces(), format);
};
function getDecimalSeparator(intl) {
  const numberWithDecimalSeparator = 1.1;
  return _optionalChain([intl, 'access', _ => _.formatNumberToParts, 'call', _2 => _2(numberWithDecimalSeparator), 'access', _3 => _3.find, 'call', _4 => _4((part) => part.type === "decimal"), 'optionalAccess', _5 => _5.value]);
}
function formatCurrencyAmount(_ref) {
  let {
    amount,
    token,
    sigFigs = 4,
    maxDecimals = 3,
    intl,
    localize = true
  } = _ref;
  if (!amount && amount !== BigInt(0) || !token) {
    return "-";
  }
  const decimalScale = _jsbi2.default.exponentiate(_jsbi2.default.BigInt(10), _jsbi2.default.BigInt(token.decimals));
  if (_jsbi2.default.equal(_jsbi2.default.BigInt(amount.toString()), _jsbi2.default.BigInt(0))) {
    return "0";
  }
  if (new Decimal(amount.toString()).div(decimalScale.toString()).lessThan(new Decimal(1).div(10 ** maxDecimals))) {
    return `<${new Decimal(1).div(10 ** maxDecimals).toString()}`;
  }
  const significant = toSignificant(amount.toString(), token.decimals, sigFigs);
  if (!localize || !intl) {
    return significant;
  }
  const [int, decimal] = significant.split(".");
  const formattedInt = intl.formatNumber(BigInt(int), {
    style: "decimal",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
  return `${formattedInt}${decimal ? `${getDecimalSeparator(intl)}${decimal}` : ""}`;
}
function formatUsdAmount(_ref2) {
  let {
    amount,
    intl
  } = _ref2;
  return formatCurrencyAmount({
    amount: _viem.parseUnits.call(void 0, Number(amount).toFixed(2) || "0", 2),
    token: {
      decimals: 2
    },
    intl
  });
}





exports.formatCurrencyAmount = formatCurrencyAmount; exports.formatUsdAmount = formatUsdAmount; exports.getDecimalSeparator = getDecimalSeparator; exports.toSignificantFromBigDecimal = toSignificantFromBigDecimal;
