"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _link = require('../link');
var _styledcomponents = require('styled-components'); var _styledcomponents2 = _interopRequireDefault(_styledcomponents);
var _icons = require('../../icons');
var _theme = require('../../theme');
const StyledLink = _styledcomponents2.default.call(void 0, _link.Link)`
  display: flex;
  text-decoration: none;
  border-radius: ${(_ref) => {
  let {
    theme
  } = _ref;
  return theme.spacing(4);
}};
`;
const StyledIcon = _styledcomponents2.default.call(void 0, _icons.TwitterIcon)`
  ${(_ref2) => {
  let {
    theme: {
      palette
    }
  } = _ref2;
  return `
  color: ${_theme.colors[palette.mode].typography.typo4}
`;
}}
`;
const TwitterShareLinkButton = (_ref3) => {
  let {
    text,
    url,
    children,
    ...linkProps
  } = _ref3;
  const linkHref = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(url)}`;
  return /* @__PURE__ */ _react2.default.createElement(StyledLink, _extends({
    href: linkHref,
    target: "_blank"
  }, linkProps), /* @__PURE__ */ _react2.default.createElement(StyledIcon, null), children);
};


exports.TwitterShareLinkButton = TwitterShareLinkButton;
