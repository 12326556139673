"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function MoreVertIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 24 24",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M14.75 19C14.75 20.52 13.52 21.75 12 21.75C10.48 21.75 9.25 20.52 9.25 19C9.25 17.48 10.48 16.25 12 16.25C13.52 16.25 14.75 17.48 14.75 19ZM10.75 19C10.75 19.69 11.31 20.25 12 20.25C12.69 20.25 13.25 19.69 13.25 19C13.25 18.31 12.69 17.75 12 17.75C11.31 17.75 10.75 18.31 10.75 19Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M14.75 5C14.75 6.52 13.52 7.75 12 7.75C10.48 7.75 9.25 6.52 9.25 5C9.25 3.48 10.48 2.25 12 2.25C13.52 2.25 14.75 3.48 14.75 5ZM10.75 5C10.75 5.69 11.31 6.25 12 6.25C12.69 6.25 13.25 5.69 13.25 5C13.25 4.31 12.69 3.75 12 3.75C11.31 3.75 10.75 4.31 10.75 5Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M14.75 12C14.75 13.52 13.52 14.75 12 14.75C10.48 14.75 9.25 13.52 9.25 12C9.25 10.48 10.48 9.25 12 9.25C13.52 9.25 14.75 10.48 14.75 12ZM10.75 12C10.75 12.69 11.31 13.25 12 13.25C12.69 13.25 13.25 12.69 13.25 12C13.25 11.31 12.69 10.75 12 10.75C11.31 10.75 10.75 11.31 10.75 12Z"
  }));
}


exports.default = MoreVertIcon;
