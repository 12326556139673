"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
var _styledcomponents = require('styled-components');
var _theme = require('../theme');
function WarningCircleIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  const {
    palette: {
      mode
    }
  } = _styledcomponents.useTheme.call(void 0, );
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 24 25",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M12 21.25C16.9706 21.25 21 17.2206 21 12.25C21 7.27944 16.9706 3.25 12 3.25C7.02944 3.25 3 7.27944 3 12.25C3 17.2206 7.02944 21.25 12 21.25Z",
    stroke: _theme.colors[mode].semantic.warning.darker,
    strokeWidth: "1.6",
    strokeMiterlimit: "10",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    fill: "none"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M13 15.25C13 15.8023 12.5523 16.25 12 16.25C11.4477 16.25 11 15.8023 11 15.25C11 14.6977 11.4477 14.25 12 14.25C12.5523 14.25 13 14.6977 13 15.25Z",
    fill: _theme.colors[mode].semantic.warning.darker
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M12 12.25L12 8.25",
    stroke: _theme.colors[mode].semantic.warning.darker,
    strokeWidth: "1.6",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }));
}


exports.default = WarningCircleIcon;
