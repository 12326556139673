"use strict";Object.defineProperty(exports, "__esModule", {value: true});var YieldName = /* @__PURE__ */ ((YieldName2) => {
  YieldName2["aave"] = "Aave V3";
  YieldName2["agave"] = "Agave";
  YieldName2["beefy"] = "Beefy";
  YieldName2["sonne"] = "Sonne";
  YieldName2["exactly"] = "Exactly";
  YieldName2["yearn"] = "Yearn";
  YieldName2["euler"] = "Euler";
  YieldName2["venus"] = "Venus";
  YieldName2["moonwell"] = "Moonwell";
  return YieldName2;
})(YieldName || {});


exports.YieldName = YieldName;
