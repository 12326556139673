"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function GraphIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 20 20",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    id: "Vector",
    d: "M15.2667 10.6273H12.5C10.425 10.6273 9.375 9.45233 9.375 7.12733V4.73566C9.375 3.88566 9.475 2.66899 10.3583 2.00233C11.1 1.45233 12.1667 1.41066 13.7417 1.86899C15.8083 2.46899 17.5333 4.19399 18.1333 6.26066C18.5917 7.82733 18.55 8.90233 18 9.63566C17.3333 10.5273 16.1167 10.6273 15.2667 10.6273ZM11.9 2.80233C11.5583 2.80233 11.2917 2.86899 11.1167 3.00233C10.7917 3.24399 10.6333 3.81066 10.6333 4.73566V7.13566C10.6333 9.00233 11.35 9.38566 12.5083 9.38566H15.275C16.1917 9.38566 16.7583 9.22733 17.0083 8.90233C17.3 8.51899 17.275 7.75233 16.95 6.62733C16.4667 4.98566 15.05 3.56066 13.4083 3.08566C12.7917 2.89399 12.2917 2.80233 11.9 2.80233Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    id: "Vector_2",
    d: "M9.22466 18.9563C8.78299 18.9563 8.33299 18.923 7.88299 18.848C4.47466 18.298 1.69966 15.5313 1.14966 12.123C0.441325 7.73966 3.26632 3.60632 7.59132 2.72299C7.93299 2.65632 8.25799 2.87299 8.33299 3.20632C8.39966 3.54799 8.18299 3.87299 7.84966 3.94799C4.19132 4.69799 1.79132 8.19799 2.39966 11.923C2.86632 14.8063 5.20799 17.148 8.09132 17.6147C11.833 18.2147 15.3247 15.8063 16.0663 12.1313C16.133 11.7897 16.4663 11.573 16.7997 11.6397C17.1413 11.7063 17.358 12.0397 17.2913 12.373C16.4997 16.2647 13.0997 18.9563 9.22466 18.9563Z"
  }));
}


exports.default = GraphIcon;
