"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function RepeatIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 25 25",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    id: "Vector",
    d: "M20.7287 12.5289C20.3187 12.5289 19.9787 12.1889 19.9787 11.7789V8.45898C19.9787 7.21898 18.9687 6.20898 17.7287 6.20898H3.88867C3.47867 6.20898 3.13867 5.86898 3.13867 5.45898C3.13867 5.04898 3.47867 4.70898 3.88867 4.70898H17.7287C19.7987 4.70898 21.4787 6.38898 21.4787 8.45898V11.7789C21.4787 12.1989 21.1387 12.5289 20.7287 12.5289Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    id: "Vector_2",
    d: "M7.04868 9.37808C6.85868 9.37808 6.66868 9.30805 6.51868 9.15805L3.35867 5.99808C3.21867 5.85808 3.13867 5.66805 3.13867 5.46805C3.13867 5.26805 3.21867 5.07808 3.35867 4.93808L6.51868 1.77805C6.80868 1.48805 7.28867 1.48805 7.57867 1.77805C7.86867 2.06805 7.86867 2.54811 7.57867 2.83811L4.9487 5.46805L7.57867 8.09805C7.86867 8.38805 7.86867 8.86805 7.57867 9.15805C7.42867 9.29805 7.23868 9.37808 7.04868 9.37808Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    id: "Vector_3",
    d: "M20.7287 19.8981H6.88867C4.81867 19.8981 3.13867 18.2181 3.13867 16.1481V12.8281C3.13867 12.4181 3.47867 12.0781 3.88867 12.0781C4.29867 12.0781 4.63867 12.4181 4.63867 12.8281V16.1481C4.63867 17.3881 5.64867 18.3981 6.88867 18.3981H20.7287C21.1387 18.3981 21.4787 18.7381 21.4787 19.1481C21.4787 19.5581 21.1387 19.8981 20.7287 19.8981Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    id: "Vector_4",
    d: "M17.5678 23.0587C17.3778 23.0587 17.1878 22.9887 17.0378 22.8387C16.7478 22.5487 16.7478 22.0687 17.0378 21.7787L19.6678 19.1487L17.0378 16.5187C16.7478 16.2287 16.7478 15.7487 17.0378 15.4587C17.3278 15.1687 17.8078 15.1687 18.0978 15.4587L21.2578 18.6187C21.3978 18.7587 21.4778 18.9487 21.4778 19.1487C21.4778 19.3487 21.3978 19.5387 21.2578 19.6787L18.0978 22.8387C17.9578 22.9887 17.7678 23.0587 17.5678 23.0587Z"
  }));
}


exports.default = RepeatIcon;
