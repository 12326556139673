"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _colors = require('../colors');
const buildDrawerVariant = (mode) => ({
  MuiDrawer: {
    styleOverrides: {
      paper: {
        boxShadow: _colors.colors[mode].dropShadow.dropShadow100,
        border: "none"
      }
    }
  }
});


exports.buildDrawerVariant = buildDrawerVariant;
