"use strict";Object.defineProperty(exports, "__esModule", {value: true});var TransactionEventTypes = /* @__PURE__ */ ((TransactionEventTypes2) => {
  TransactionEventTypes2["ERC20_APPROVAL"] = "ERC20 approval";
  TransactionEventTypes2["ERC20_TRANSFER"] = "ERC20 transfer";
  TransactionEventTypes2["NATIVE_TRANSFER"] = "Native transfer";
  TransactionEventTypes2["DCA_WITHDRAW"] = "DCA Withdrew";
  TransactionEventTypes2["DCA_MODIFIED"] = "DCA Modified";
  TransactionEventTypes2["DCA_CREATED"] = "DCA Deposited";
  TransactionEventTypes2["DCA_PERMISSIONS_MODIFIED"] = "DCA Modified Permissions";
  TransactionEventTypes2["DCA_TRANSFER"] = "DCA Transferred";
  TransactionEventTypes2["DCA_TERMINATED"] = "DCA Terminated";
  TransactionEventTypes2["SWAP"] = "Swap";
  TransactionEventTypes2["EARN_CREATED"] = "Earn Created";
  TransactionEventTypes2["EARN_INCREASE"] = "Earn Increased";
  TransactionEventTypes2["EARN_WITHDRAW"] = "Earn Withdrew";
  TransactionEventTypes2["EARN_SPECIAL_WITHDRAW"] = "Earn Withdrew Specially";
  TransactionEventTypes2["EARN_CLAIM_DELAYED_WITHDRAW"] = "Earn Delayed Withdrawal Claimed";
  return TransactionEventTypes2;
})(TransactionEventTypes || {});
var TransactionStatus = /* @__PURE__ */ ((TransactionStatus2) => {
  TransactionStatus2["DONE"] = "done";
  TransactionStatus2["PENDING"] = "pending";
  return TransactionStatus2;
})(TransactionStatus || {});
var TransactionEventIncomingTypes = /* @__PURE__ */ ((TransactionEventIncomingTypes2) => {
  TransactionEventIncomingTypes2["INCOMING"] = "incoming";
  TransactionEventIncomingTypes2["OUTGOING"] = "outgoing";
  TransactionEventIncomingTypes2["SAME_ACCOUNTS"] = "same accounts";
  return TransactionEventIncomingTypes2;
})(TransactionEventIncomingTypes || {});




exports.TransactionEventIncomingTypes = TransactionEventIncomingTypes; exports.TransactionEventTypes = TransactionEventTypes; exports.TransactionStatus = TransactionStatus;
