"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function HomeIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 21 21",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    id: "Vector",
    d: "M10.6016 16.207C10.2599 16.207 9.97656 15.9237 9.97656 15.582V13.082C9.97656 12.7404 10.2599 12.457 10.6016 12.457C10.9432 12.457 11.2266 12.7404 11.2266 13.082V15.582C11.2266 15.9237 10.9432 16.207 10.6016 16.207Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    id: "Vector_2",
    d: "M15.2697 19.382H5.93633C4.41966 19.382 3.03633 18.2154 2.78633 16.7237L1.67799 10.082C1.49466 9.04871 2.00299 7.72371 2.82799 7.06538L8.60299 2.44038C9.71966 1.54038 11.478 1.54871 12.603 2.44871L18.378 7.06538C19.1947 7.72371 19.6947 9.04871 19.528 10.082L18.4197 16.7154C18.1697 18.1904 16.753 19.382 15.2697 19.382ZM10.5947 3.02371C10.153 3.02371 9.71133 3.15705 9.38633 3.41538L3.61133 8.04871C3.14466 8.42371 2.81133 9.29038 2.91133 9.88204L4.01966 16.5154C4.16966 17.3904 5.04466 18.132 5.93633 18.132H15.2697C16.1613 18.132 17.0363 17.3904 17.1863 16.507L18.2947 9.87371C18.3863 9.29038 18.053 8.40705 17.5947 8.04038L11.8197 3.42371C11.4863 3.15705 11.0363 3.02371 10.5947 3.02371Z"
  }));
}


exports.default = HomeIcon;
