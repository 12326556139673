"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _colors = require('../colors');
var _constants = require('../constants');
const buildChipVariant = (mode) => ({
  MuiChip: {
    styleOverrides: {
      label: {
        padding: 0
      },
      root: {
        padding: `${_constants.SPACING.call(void 0, 1)} ${_constants.SPACING.call(void 0, 2)}`
      },
      colorPrimary: {
        color: _colors.colors[mode].typography.typo2,
        backgroundColor: _colors.colors[mode].background.tertiary,
        border: `1.5px solid ${_colors.colors[mode].border.border2}`
      },
      colorSecondary: {
        color: _colors.colors[mode].typography.typo3,
        backgroundColor: _colors.colors[mode].background.quartery,
        border: `1.5px solid ${_colors.colors[mode].border.border1}`
      },
      filledSecondary: {
        color: _colors.colors[mode].typography.typo2,
        backgroundColor: _colors.colors[mode].background.secondary,
        border: `1.5px solid ${_colors.colors[mode].border.border1}`
      },
      colorSuccess: {
        color: _colors.colors[mode].semantic.success.darker,
        backgroundColor: _colors.colors[mode].semanticBackground.success
      },
      colorWarning: {
        color: _colors.colors[mode].semantic.warning.darker,
        backgroundColor: _colors.colors[mode].semanticBackground.warning
      },
      colorError: {
        color: _colors.colors[mode].semantic.error.darker,
        backgroundColor: _colors.colors[mode].semanticBackground.error
      },
      deleteIcon: {
        "&.MuiSvgIcon-root": {
          margin: 0,
          color: _colors.colors[mode].typography.typo3,
          fontSize: _constants.SPACING.call(void 0, 5)
        }
      }
    }
  }
});


exports.buildChipVariant = buildChipVariant;
