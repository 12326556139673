"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _notistack = require('notistack');
var _styledcomponents = require('styled-components'); var _styledcomponents2 = _interopRequireDefault(_styledcomponents);
var _theme = require('../../theme');
const StyledMaterialDesignContent = _styledcomponents2.default.call(void 0, (0, _notistack.MaterialDesignContent))((_ref) => {
  let {
    theme: {
      palette,
      spacing
    }
  } = _ref;
  return {
    "&.notistack-MuiContent": {
      backgroundColor: _theme.colors[palette.mode].background.secondary,
      padding: spacing(3),
      borderRadius: spacing(2),
      "& > div": {
        gap: spacing(2),
        color: _theme.colors[palette.mode].typography.typo2,
        fontWeight: "bold"
      },
      "& .MuiButton-root": {
        padding: 0
      }
    },
    "&.notistack-MuiContent-success": {
      border: `1px solid ${_theme.colors[palette.mode].semantic.success.darker}`
    },
    "&.notistack-MuiContent-error": {
      border: `1px solid ${_theme.colors[palette.mode].semantic.error.primary}`
    },
    "&.notistack-MuiContent-warning": {
      border: `1px solid ${_theme.colors[palette.mode].semantic.warning.primary}`
    }
  };
});


exports.StyledMaterialDesignContent = StyledMaterialDesignContent;
