"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _notistack = require('notistack');
var _snackbar = require('../../components/snackbar');
var _icons = require('../../icons');
const CustomSnackbarProvider = (_ref) => {
  let {
    children
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_notistack.SnackbarProvider, {
    Components: {
      success: _snackbar.StyledMaterialDesignContent,
      error: _snackbar.StyledMaterialDesignContent,
      warning: _snackbar.StyledMaterialDesignContent
    },
    iconVariant: {
      success: /* @__PURE__ */ _react2.default.createElement(_icons.TickCircleIcon, {
        color: "success"
      }),
      warning: /* @__PURE__ */ _react2.default.createElement(_icons.WarningCircleIcon, null),
      error: /* @__PURE__ */ _react2.default.createElement(_icons.WarningTriangleIcon, null)
    }
  }, children);
};



exports.SnackbarProvider = CustomSnackbarProvider; exports.useSnackbar = _notistack.useSnackbar;
