"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactvirtuoso = require('react-virtuoso');
var _styledcomponents = require('styled-components'); var _styledcomponents2 = _interopRequireDefault(_styledcomponents);
var _containerbox = require('../container-box');
var _theme = require('../../theme');
const StyledVirtuoso = _styledcomponents2.default.call(void 0, _reactvirtuoso.Virtuoso)`
  .virtuoso-list {
    gap: ${(_ref) => {
  let {
    $gap = 2
  } = _ref;
  return _theme.SPACING.call(void 0, $gap);
}};
  }
`;
const StyledList = _styledcomponents2.default.call(void 0, _containerbox.ContainerBox).attrs({
  flex: 1,
  flexDirection: "column"
})``;
const VirtuosoComponents = {
  List: /* @__PURE__ */ _react.forwardRef.call(void 0, function VirtuosoList(props, ref) {
    return /* @__PURE__ */ _react2.default.createElement(StyledList, _extends({
      className: "virtuoso-list"
    }, props, {
      ref
    }));
  })
};
const VirtualizedList = (_ref2) => {
  let {
    data,
    itemContent,
    fetchMore,
    context,
    gap
  } = _ref2;
  return /* @__PURE__ */ _react2.default.createElement(StyledVirtuoso, {
    $gap: gap,
    data,
    itemContent,
    endReached: fetchMore,
    context,
    components: VirtuosoComponents
  });
};


exports.VirtualizedList = VirtualizedList;
