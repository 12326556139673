"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
var _styledcomponents = require('styled-components');
var _theme = require('../theme');
function SuccessCircleIcon(_ref) {
  let {
    size = "162px",
    ...props
  } = _ref;
  const {
    palette: {
      mode
    }
  } = _styledcomponents.useTheme.call(void 0, );
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 131 131",
    style: {
      fontSize: size
    }
  }, props), /* @__PURE__ */ _react2.default.createElement("circle", {
    cx: "65.4766",
    cy: "65.3184",
    r: "65.1855",
    fill: _theme.colors[mode].semantic.success.light,
    fillOpacity: "0.4"
  }), /* @__PURE__ */ _react2.default.createElement("circle", {
    cx: "66.2559",
    cy: "65.1855",
    r: "52.5488",
    fill: _theme.colors[mode].semantic.success.primary
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M85.9389 51.25L57.8042 79.3848L45.0156 66.5962",
    stroke: _theme.colors[mode].background.tertiary,
    strokeWidth: "5.11541",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    fill: "none"
  }));
}


exports.default = SuccessCircleIcon;
