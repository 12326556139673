"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function Wallet2Icon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 24 25",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M13 10.1719H7C6.59 10.1719 6.25 9.83187 6.25 9.42188C6.25 9.01188 6.59 8.67188 7 8.67188H13C13.41 8.67188 13.75 9.01188 13.75 9.42188C13.75 9.83187 13.41 10.1719 13 10.1719Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M19.0399 15.2211C17.5299 15.2211 16.2499 14.1011 16.1299 12.6611C16.0499 11.8311 16.3499 11.0211 16.9499 10.4311C17.4499 9.91109 18.1599 9.62109 18.9099 9.62109H20.9999C21.9899 9.65109 22.7499 10.4311 22.7499 11.3911V13.4511C22.7499 14.4111 21.9899 15.1911 21.0299 15.2211H19.0399ZM20.9699 11.1211H18.9199C18.5699 11.1211 18.2499 11.2511 18.0199 11.4911C17.7299 11.7711 17.5899 12.1511 17.6299 12.5311C17.6799 13.1911 18.3199 13.7211 19.0399 13.7211H20.9999C21.1299 13.7211 21.2499 13.6011 21.2499 13.4511V11.3911C21.2499 11.2411 21.1299 11.1311 20.9699 11.1211Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M16 21.6719H7C3.56 21.6719 1.25 19.3619 1.25 15.9219V8.92188C1.25 5.84188 3.14998 3.61188 6.09998 3.24188C6.36998 3.20188 6.68 3.17188 7 3.17188H16C16.24 3.17188 16.55 3.18187 16.87 3.23187C19.82 3.57187 21.75 5.81188 21.75 8.92188V10.3719C21.75 10.7819 21.41 11.1219 21 11.1219H18.92C18.57 11.1219 18.25 11.2519 18.02 11.4919L18.01 11.5019C17.73 11.7719 17.6 12.1419 17.63 12.5219C17.68 13.1819 18.32 13.7119 19.04 13.7119H21C21.41 13.7119 21.75 14.0519 21.75 14.4619V15.9119C21.75 19.3619 19.44 21.6719 16 21.6719ZM7 4.67188C6.76 4.67188 6.52999 4.69186 6.29999 4.72186C4.09999 5.00186 2.75 6.60188 2.75 8.92188V15.9219C2.75 18.5019 4.42 20.1719 7 20.1719H16C18.58 20.1719 20.25 18.5019 20.25 15.9219V15.2219H19.04C17.53 15.2219 16.25 14.1019 16.13 12.6619C16.05 11.8419 16.35 11.0219 16.95 10.4419C17.47 9.91189 18.17 9.62189 18.92 9.62189H20.25V8.92188C20.25 6.58188 18.88 4.97185 16.66 4.71185C16.42 4.67185 16.21 4.67188 16 4.67188H7Z"
  }));
}


exports.default = Wallet2Icon;
