"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function DollarSquareIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 25 25",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M14.0759 17.7287H11.5659C9.92594 17.7287 8.59594 16.3487 8.59594 14.6487C8.59594 14.2387 8.93594 13.8987 9.34594 13.8987C9.75594 13.8987 10.0959 14.2387 10.0959 14.6487C10.0959 15.5187 10.7559 16.2287 11.5659 16.2287H14.0759C14.7259 16.2287 15.2659 15.6487 15.2659 14.9487C15.2659 14.0787 14.9559 13.9087 14.4459 13.7287L10.4159 12.3087C9.63594 12.0387 8.58594 11.4587 8.58594 9.66867C8.58594 8.12867 9.79594 6.88867 11.2759 6.88867H13.7859C15.4259 6.88867 16.7559 8.26867 16.7559 9.96867C16.7559 10.3787 16.4159 10.7187 16.0059 10.7187C15.5959 10.7187 15.2559 10.3787 15.2559 9.96867C15.2559 9.09867 14.5959 8.38867 13.7859 8.38867H11.2759C10.6259 8.38867 10.0859 8.96867 10.0859 9.66867C10.0859 10.5387 10.3959 10.7087 10.9059 10.8887L14.9359 12.3087C15.7159 12.5787 16.7659 13.1587 16.7659 14.9487C16.7559 16.4787 15.5559 17.7287 14.0759 17.7287Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M12.6758 19.0586C12.2658 19.0586 11.9258 18.7186 11.9258 18.3086V6.30859C11.9258 5.89859 12.2658 5.55859 12.6758 5.55859C13.0858 5.55859 13.4258 5.89859 13.4258 6.30859V18.3086C13.4258 18.7186 13.0858 19.0586 12.6758 19.0586Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M15.6758 23.0586H9.67578C4.24578 23.0586 1.92578 20.7386 1.92578 15.3086V9.30859C1.92578 3.87859 4.24578 1.55859 9.67578 1.55859H15.6758C21.1058 1.55859 23.4258 3.87859 23.4258 9.30859V15.3086C23.4258 20.7386 21.1058 23.0586 15.6758 23.0586ZM9.67578 3.05859C5.06578 3.05859 3.42578 4.69859 3.42578 9.30859V15.3086C3.42578 19.9186 5.06578 21.5586 9.67578 21.5586H15.6758C20.2858 21.5586 21.9258 19.9186 21.9258 15.3086V9.30859C21.9258 4.69859 20.2858 3.05859 15.6758 3.05859H9.67578Z"
  }));
}


exports.default = DollarSquareIcon;
