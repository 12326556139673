"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function RepeatIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 19 19",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    id: "Vector",
    d: "M11.875 17.4297H7.375C3.3025 17.4297 1.5625 15.6897 1.5625 11.6172V7.11719C1.5625 3.04469 3.3025 1.30469 7.375 1.30469H11.875C15.9475 1.30469 17.6875 3.04469 17.6875 7.11719V11.6172C17.6875 15.6897 15.9475 17.4297 11.875 17.4297ZM7.375 2.42969C3.9175 2.42969 2.6875 3.65969 2.6875 7.11719V11.6172C2.6875 15.0747 3.9175 16.3047 7.375 16.3047H11.875C15.3325 16.3047 16.5625 15.0747 16.5625 11.6172V7.11719C16.5625 3.65969 15.3325 2.42969 11.875 2.42969H7.375Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    id: "Vector_2",
    d: "M9.625 13.875C7.2475 13.875 5.3125 11.94 5.3125 9.5625C5.3125 7.185 7.2475 5.25 9.625 5.25C10.105 5.25 10.585 5.3175 11.0425 5.46C11.3425 5.55 11.5075 5.865 11.4175 6.165C11.3275 6.465 11.0125 6.63 10.7125 6.54C10.3675 6.435 10 6.3825 9.625 6.3825C7.87 6.3825 6.4375 7.815 6.4375 9.57C6.4375 11.325 7.87 12.7575 9.625 12.7575C11.38 12.7575 12.8125 11.325 12.8125 9.57C12.8125 8.9325 12.625 8.325 12.28 7.8C12.1075 7.545 12.175 7.1925 12.4375 7.02C12.6925 6.8475 13.045 6.915 13.2175 7.1775C13.69 7.89 13.945 8.715 13.945 9.57C13.9375 11.9475 12.0025 13.875 9.625 13.875Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    id: "Vector_3",
    d: "M11.4099 6.66606C11.2524 6.66606 11.0949 6.59856 10.9824 6.47106L9.72988 5.03856C9.52738 4.80606 9.54989 4.44605 9.78239 4.24355C10.0149 4.04105 10.3749 4.06355 10.5774 4.29605L11.8299 5.72856C12.0324 5.96106 12.0099 6.32106 11.7774 6.52356C11.6724 6.62106 11.5449 6.66606 11.4099 6.66606Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    id: "Vector_4",
    d: "M9.95492 7.73086C9.78242 7.73086 9.60992 7.64836 9.49742 7.49836C9.31742 7.25086 9.36993 6.89836 9.61743 6.71086L11.0724 5.64586C11.3199 5.46586 11.6724 5.51836 11.8599 5.76586C12.0399 6.01336 11.9874 6.36586 11.7399 6.55336L10.2849 7.61836C10.1874 7.69336 10.0674 7.73086 9.95492 7.73086Z"
  }));
}


exports.default = RepeatIcon;
