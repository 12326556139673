"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _colors = require('../colors');
var _constants = require('../constants');
const buildCardVariant = (mode) => ({
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: _constants.SPACING.call(void 0, 4),
        backgroundColor: _colors.colors[mode].background.tertiary
      }
    }
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        display: "flex",
        flexGrow: "1",
        flexDirection: "column",
        padding: 0,
        "&:last-child": {
          paddingBottom: 0
        }
      }
    }
  }
});


exports.buildCardVariant = buildCardVariant;
