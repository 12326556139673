"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function ContentCopyIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 20 20",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M9.24935 18.9577H5.74935C2.49102 18.9577 1.04102 17.5077 1.04102 14.2493V10.7493C1.04102 7.49102 2.49102 6.04102 5.74935 6.04102H9.24935C12.5077 6.04102 13.9577 7.49102 13.9577 10.7493V14.2493C13.9577 17.5077 12.5077 18.9577 9.24935 18.9577ZM5.74935 7.29102C3.16602 7.29102 2.29102 8.16602 2.29102 10.7493V14.2493C2.29102 16.8327 3.16602 17.7077 5.74935 17.7077H9.24935C11.8327 17.7077 12.7077 16.8327 12.7077 14.2493V10.7493C12.7077 8.16602 11.8327 7.29102 9.24935 7.29102H5.74935Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M14.2493 13.9577H13.3327C12.991 13.9577 12.7077 13.6743 12.7077 13.3327V10.7493C12.7077 8.16602 11.8327 7.29102 9.24935 7.29102H6.66602C6.32435 7.29102 6.04102 7.00768 6.04102 6.66602V5.74935C6.04102 2.49102 7.49102 1.04102 10.7493 1.04102H14.2493C17.5077 1.04102 18.9577 2.49102 18.9577 5.74935V9.24935C18.9577 12.5077 17.5077 13.9577 14.2493 13.9577ZM13.9577 12.7077H14.2493C16.8327 12.7077 17.7077 11.8327 17.7077 9.24935V5.74935C17.7077 3.16602 16.8327 2.29102 14.2493 2.29102H10.7493C8.16602 2.29102 7.29102 3.16602 7.29102 5.74935V6.04102H9.24935C12.5077 6.04102 13.9577 7.49101 13.9577 10.7493V12.7077Z"
  }));
}


exports.default = ContentCopyIcon;
