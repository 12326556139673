"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function GCalendarIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 200 200",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M152.637 47.363H47.363v105.273h105.273z",
    fill: "#fff"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M152.637 200L200 152.637h-47.363z",
    fill: "#f72a25"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M200 47.363h-47.363v105.273H200z",
    fill: "#fbbc04"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M152.637 152.637H47.363V200h105.273z",
    fill: "#34a853"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M0 152.637v31.576A15.788 15.788 0 0 0 15.788 200h31.576v-47.363z",
    fill: "#188038"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M200 47.363V15.788A15.79 15.79 0 0 0 184.212 0h-31.575v47.363z",
    fill: "#1967d2"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M15.788 0A15.79 15.79 0 0 0 0 15.788v136.849h47.363V47.363h105.274V0z",
    fill: "#4285f4"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M68.962 129.02c-3.939-2.653-6.657-6.543-8.138-11.67l9.131-3.76c.83 3.158 2.279 5.599 4.346 7.341 2.051 1.742 4.557 2.588 7.471 2.588 2.995 0 5.55-.911 7.699-2.718 2.148-1.823 3.223-4.134 3.223-6.934 0-2.865-1.139-5.208-3.402-7.031s-5.111-2.718-8.496-2.718h-5.273v-9.033h4.736c2.913 0 5.387-.781 7.389-2.376 2.002-1.579 2.995-3.743 2.995-6.494 0-2.441-.895-4.395-2.686-5.859s-4.053-2.197-6.803-2.197c-2.686 0-4.818.716-6.396 2.148s-2.767 3.255-3.451 5.273l-9.033-3.76c1.204-3.402 3.402-6.396 6.624-8.984s7.34-3.89 12.337-3.89c3.695 0 7.031.716 9.977 2.148s5.257 3.418 6.934 5.941c1.676 2.539 2.507 5.387 2.507 8.545 0 3.223-.781 5.941-2.327 8.187-1.546 2.23-3.467 3.955-5.729 5.143v.537a17.39 17.39 0 0 1 7.34 5.729c1.904 2.572 2.865 5.632 2.865 9.212s-.911 6.771-2.718 9.57c-1.823 2.799-4.329 5.013-7.52 6.624s-6.787 2.425-10.775 2.425c-4.622 0-8.887-1.318-12.826-3.988zm56.087-45.312l-10.026 7.243-5.013-7.601 17.985-12.972h6.901v61.198h-9.847z",
    fill: "#1a73e8"
  }));
}


exports.default = GCalendarIcon;
