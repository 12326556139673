"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _styledcomponents = require('styled-components');
var _constants = require('../../theme/constants');
var _theme = require('../../theme');
function CoinWrapper(_ref) {
  let {
    style: {
      width = "40px",
      height = "40px",
      ...style
    },
    children
  } = _ref;
  const {
    palette: {
      mode
    }
  } = _styledcomponents.useTheme.call(void 0, );
  return /* @__PURE__ */ _react2.default.createElement("div", {
    style: {
      ...style,
      width,
      height,
      position: "relative",
      padding: _constants.SPACING.call(void 0, 3),
      borderRadius: "50%",
      border: `0.25px solid #ffffffa6`,
      // White with 65% opacity
      background: "#FFFFFF01",
      // White with 1% opacity
      backdropFilter: "blur(5px)",
      boxShadow: _theme.colors[mode].dropShadow.dropShadow100,
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }
  }, children);
}


exports.CoinWrapper = CoinWrapper;
