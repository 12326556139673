"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _button = require('../button');
var _menu = require('../menu');
var _icons = require('../../icons');
var _menuitem = require('../menuitem');
var _divider = require('../divider');
var _typography = require('../typography');
var _styledcomponents = require('styled-components'); var _styledcomponents2 = _interopRequireDefault(_styledcomponents);
var _theme = require('../../theme');
var _containerbox = require('../container-box');
const StyledButton = _styledcomponents2.default.call(void 0, _button.Button)`
  ${(_ref) => {
  let {
    theme: {
      spacing
    }
  } = _ref;
  return `
  padding: ${spacing(1)} !important;
  max-width: none;
  min-width: 0;
  display: flex;
  gap: ${spacing(1)};
  justify-content: space-between;

  &:hover {
    border-radius: ${spacing(3)};
  }
`;
}}
`;
const StyledMenuItem = _styledcomponents2.default.call(void 0, _menuitem.MenuItem)`
  ${(_ref2) => {
  let {
    theme: {
      palette,
      spacing
    }
  } = _ref2;
  return `
  transition: background-color 0.2s ease-in-out;
  border-radius: ${spacing(2)};
  :hover {
    background-color: ${_theme.colors[palette.mode].background.emphasis};
  }
  `;
}}
`;
var OptionsMenuOptionType = /* @__PURE__ */ function(OptionsMenuOptionType2) {
  OptionsMenuOptionType2["divider"] = "divider";
  OptionsMenuOptionType2["option"] = "option";
  return OptionsMenuOptionType2;
}(OptionsMenuOptionType || {});
const DividerItem = () => /* @__PURE__ */ _react2.default.createElement(_divider.DividerBorder2, null);
const BaseOptionItem = (_ref3) => {
  let {
    option: {
      label,
      closeOnClick = true,
      color: itemColor,
      control,
      onClick,
      Icon: ItemIcon,
      secondaryLabel,
      disabled,
      customClassname,
      onRender
    },
    handleItemClick,
    mode
  } = _ref3;
  _react.useEffect.call(void 0, () => {
    if (onRender)
      onRender();
  }, [onRender]);
  return /* @__PURE__ */ _react2.default.createElement(StyledMenuItem, {
    onClick: (e) => handleItemClick(e, closeOnClick, onClick),
    color: itemColor,
    disabled
  }, ItemIcon && /* @__PURE__ */ _react2.default.createElement(ItemIcon, {
    color: _nullishCoalesce(itemColor, () => ( "info"))
  }), /* @__PURE__ */ _react2.default.createElement(_containerbox.ContainerBox, {
    flexDirection: "column",
    fullWidth: true,
    className: customClassname
  }, typeof label === "string" ? /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "bodySmallRegular",
    color: itemColor ? `${itemColor}.dark` : _theme.colors[mode].typography.typo2
  }, label) : label, secondaryLabel && /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "bodyExtraSmall",
    color: itemColor ? `${itemColor}.dark` : _theme.colors[mode].typography.typo3
  }, secondaryLabel)), control);
};
const OptionItem = (_ref4) => {
  let {
    option,
    handleItemClick,
    mode
  } = _ref4;
  const [anchorEl, setAnchorEl] = _react.useState.call(void 0, null);
  const open = Boolean(anchorEl);
  const {
    closeOnClick = true,
    onClick,
    options
  } = option;
  const handleClick = (e, fromItem, itemOnClick) => {
    if (_optionalChain([options, 'optionalAccess', _ => _.length]) && fromItem) {
      setAnchorEl(e.currentTarget);
    } else {
      handleItemClick(e, closeOnClick, itemOnClick || onClick);
    }
  };
  const handleClose = () => setAnchorEl(null);
  return /* @__PURE__ */ _react2.default.createElement(_react2.default.Fragment, null, /* @__PURE__ */ _react2.default.createElement(BaseOptionItem, {
    option,
    handleItemClick: (e) => handleClick(e, true),
    mode
  }), _optionalChain([options, 'optionalAccess', _2 => _2.length]) && /* @__PURE__ */ _react2.default.createElement(_menu.Menu, {
    anchorEl,
    open,
    onClose: handleClose,
    onClick: (e) => e.stopPropagation(),
    anchorOrigin: {
      vertical: "top",
      horizontal: "right"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left"
    },
    slotProps: {
      paper: {
        style: {
          maxHeight: "320px",
          overflow: "auto"
        }
      }
    }
  }, options.map((subOption, index) => {
    if (subOption.type === OptionsMenuOptionType.option) {
      return /* @__PURE__ */ _react2.default.createElement(OptionItem, {
        key: index,
        option: subOption,
        mode,
        handleItemClick: (e, nonUseFull, actionClick) => handleClick(e, false, actionClick)
      });
    } else if (subOption.type === OptionsMenuOptionType.divider) {
      return /* @__PURE__ */ _react2.default.createElement(DividerItem, {
        key: index
      });
    }
  })));
};
const OptionsMenuItems = (_ref5) => {
  let {
    options,
    handleClose,
    anchorEl
  } = _ref5;
  const open = Boolean(anchorEl);
  const {
    palette: {
      mode
    }
  } = _styledcomponents.useTheme.call(void 0, );
  const handleCloseWithAction = _react.useCallback.call(void 0, (event, closeOnClick, action) => {
    event.stopPropagation();
    if (closeOnClick) {
      handleClose();
    }
    if (action)
      action();
  }, [handleClose]);
  return /* @__PURE__ */ _react2.default.createElement(_menu.Menu, {
    anchorEl,
    open,
    onClose: handleClose,
    onClick: (e) => e.stopPropagation(),
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left"
    },
    slotProps: {
      paper: {
        style: {
          maxHeight: "320px",
          overflow: "auto"
        }
      }
    }
  }, options.map((option, index) => {
    if (option.type === OptionsMenuOptionType.option) {
      return /* @__PURE__ */ _react2.default.createElement(OptionItem, {
        key: index,
        option,
        mode,
        handleItemClick: handleCloseWithAction
      });
    } else if (option.type === OptionsMenuOptionType.divider) {
      return /* @__PURE__ */ _react2.default.createElement(DividerItem, {
        key: index
      });
    }
  }));
};
const OptionsMenu = (_ref6) => {
  let {
    options,
    color = "info",
    variant = "text",
    mainDisplay,
    size = "small",
    blockMenuOpen,
    showEndIcon = true,
    setIsMenuOpen,
    alwaysUseTypography = false,
    customClassname,
    fullWidth,
    dataAttrs
  } = _ref6;
  const [anchorEl, setAnchorEl] = _react.useState.call(void 0, null);
  const handleClick = _react.useCallback.call(void 0, (event) => {
    if (!blockMenuOpen) {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
      if (setIsMenuOpen)
        setIsMenuOpen(true);
    }
  }, [setAnchorEl, blockMenuOpen]);
  const handleClose = _react.useCallback.call(void 0, () => {
    setAnchorEl(null);
    if (setIsMenuOpen)
      setIsMenuOpen(false);
  }, [setAnchorEl]);
  return /* @__PURE__ */ _react2.default.createElement(_containerbox.ContainerBox, {
    flex: fullWidth ? 1 : void 0
  }, /* @__PURE__ */ _react2.default.createElement(StyledButton, _extends({
    variant,
    color,
    size,
    onClick: handleClick,
    endIcon: showEndIcon && /* @__PURE__ */ _react2.default.createElement(_icons.KeyboardArrowDownIcon, {
      fontSize: "large"
    }),
    className: customClassname,
    fullWidth
  }, dataAttrs || {}), typeof mainDisplay === "string" || alwaysUseTypography ? /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "bodySmallBold",
    color: (_ref7) => {
      let {
        palette: {
          mode
        }
      } = _ref7;
      return _theme.colors[mode].typography.typo2;
    }
  }, mainDisplay) : mainDisplay), /* @__PURE__ */ _react2.default.createElement(OptionsMenuItems, {
    options,
    anchorEl,
    handleClose
  }));
};




exports.OptionsMenu = OptionsMenu; exports.OptionsMenuItems = OptionsMenuItems; exports.OptionsMenuOptionType = OptionsMenuOptionType;
