"use strict";Object.defineProperty(exports, "__esModule", {value: true});const DEFAULT_SPACING = 4;
const DEFAULT_BORDER_RADIUS = 16;
const SPACING = (value) => `${DEFAULT_SPACING * value}px`;
const MAX_FORM_WIDTH = SPACING(158);
const baseSpacingScale = {
  none: SPACING(0),
  // 0px
  s01: SPACING(1),
  // 4px
  s02: SPACING(2),
  // 8px
  s03: SPACING(3),
  // 12px
  s04: SPACING(4),
  // 16px
  s05: SPACING(6),
  // 24px
  s06: SPACING(8),
  // 32px
  s07: SPACING(12)
  // 48px
};






exports.DEFAULT_BORDER_RADIUS = DEFAULT_BORDER_RADIUS; exports.DEFAULT_SPACING = DEFAULT_SPACING; exports.MAX_FORM_WIDTH = MAX_FORM_WIDTH; exports.SPACING = SPACING; exports.baseSpacingScale = baseSpacingScale;
