"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { newObj[key] = obj[key]; } } } newObj.default = obj; return newObj; } } function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var React = _interopRequireWildcard(_react);
var _Box = require('@mui/material/Box'); var _Box2 = _interopRequireDefault(_Box);
var _CircularProgress = require('@mui/material/CircularProgress'); var _CircularProgress2 = _interopRequireDefault(_CircularProgress);
var _theme = require('../../theme');
var _omit = require('lodash/omit'); var _omit2 = _interopRequireDefault(_omit);
function CircularProgressWithBrackground(props) {
  return (
    // eslint-disable-next-line react/destructuring-assignment
    /* @__PURE__ */ React.createElement(_Box2.default, {
      sx: {
        position: "relative",
        ...props.sx || {}
      }
    }, /* @__PURE__ */ React.createElement(_CircularProgress2.default, _extends({
      variant: "determinate",
      sx: {
        color: (_ref) => {
          let {
            palette: {
              mode
            }
          } = _ref;
          return _theme.colors[mode].accent.accent200;
        }
      }
    }, _omit2.default.call(void 0, props, "sx"), {
      value: 100
    })), /* @__PURE__ */ React.createElement(_CircularProgress2.default, _extends({
      variant: "determinate",
      disableShrink: true,
      sx: {
        color: (_ref2) => {
          let {
            palette: {
              mode
            }
          } = _ref2;
          return _theme.colors[mode].accent.accent400;
        },
        animationDuration: "550ms",
        position: "absolute",
        left: 0,
        [`& .${_CircularProgress.circularProgressClasses.circle}`]: {
          strokeLinecap: "round"
        }
      }
    }, _omit2.default.call(void 0, props, "sx"))))
  );
}


exports.CircularProgressWithBrackground = CircularProgressWithBrackground;
