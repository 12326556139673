"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _colors = require('../colors');
var _constants = require('../constants');
const buildToggleButtonGroupVariant = (mode) => ({
  MuiToggleButtonGroup: {
    styleOverrides: {
      root: {
        gap: _constants.SPACING.call(void 0, 2),
        border: "none"
      },
      grouped: {
        borderRadius: `${_constants.SPACING.call(void 0, 2)}`,
        "&:not(:last-of-type)": {
          borderColor: _colors.colors[mode].border.border1,
          borderRadius: `${_constants.SPACING.call(void 0, 2)}`
        },
        "&:not(:first-of-type)": {
          borderColor: _colors.colors[mode].border.border1,
          borderRadius: `${_constants.SPACING.call(void 0, 2)}`
        },
        "&.Mui-selected": {
          borderColor: `${_colors.colors[mode].accentPrimary}`,
          borderWidth: "1.5px"
        }
      }
    }
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        background: _colors.colors[mode].background.secondary,
        padding: `${_constants.SPACING.call(void 0, 2.5)} ${_constants.SPACING.call(void 0, 3)}`,
        textTransform: "none",
        transition: "background-color 300ms, border-color 300ms",
        ":hover": {
          background: _colors.colors[mode].background.tertiary
        },
        "&.Mui-selected": {
          boxShadow: _colors.colors[mode].dropShadow.dropShadow100,
          background: _colors.colors[mode].background.tertiary,
          "& .MuiTypography-root": {
            fontWeight: 600,
            color: _colors.colors[mode].accentPrimary
          }
        }
      }
    }
  }
});


exports.buildToggleButtonGroupVariant = buildToggleButtonGroupVariant;
