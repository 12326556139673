"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _Divider = require('@mui/material/Divider'); var _Divider2 = _interopRequireDefault(_Divider);
var _styledcomponents = require('styled-components'); var _styledcomponents2 = _interopRequireDefault(_styledcomponents);
var _theme = require('../../theme');
const BaseDivider = _styledcomponents2.default.call(void 0, _Divider2.default)`
  ${(_ref) => {
  let {
    $thin = true
  } = _ref;
  return `
    ${$thin ? "border-width: 0.5px; border-bottom-width: thin;" : ""}
  `;
}}
`;
const DividerBorderAccent = _styledcomponents2.default.call(void 0, BaseDivider)`
  ${(_ref2) => {
  let {
    theme: {
      palette: {
        mode
      }
    }
  } = _ref2;
  return `
    border-color: ${_theme.colors[mode].border.accent};
  `;
}}
`;
const DividerBorder1 = _styledcomponents2.default.call(void 0, BaseDivider)`
  ${(_ref3) => {
  let {
    theme: {
      palette: {
        mode
      }
    }
  } = _ref3;
  return `
    border-color: ${_theme.colors[mode].border.border1};
  `;
}}
`;
const DividerBorder2 = _styledcomponents2.default.call(void 0, BaseDivider)`
  ${(_ref4) => {
  let {
    theme: {
      palette: {
        mode
      }
    }
  } = _ref4;
  return `
  border-color: ${_theme.colors[mode].border.border2};
`;
}}
`;





exports.Divider = _Divider2.default; exports.DividerBorder1 = DividerBorder1; exports.DividerBorder2 = DividerBorder2; exports.DividerBorderAccent = DividerBorderAccent;
