"use strict";Object.defineProperty(exports, "__esModule", {value: true});var QuoteErrors = /* @__PURE__ */ ((QuoteErrors2) => {
  QuoteErrors2["TIMEOUT"] = "Timeout";
  QuoteErrors2["REFERRAL_CODE"] = "ReferralCode";
  QuoteErrors2["BIGINT_CONVERSION"] = "BigIntConversion";
  QuoteErrors2["NETWORK_REQUEST"] = "NetworkRequest";
  QuoteErrors2["UNKNOWN"] = "Unknown";
  return QuoteErrors2;
})(QuoteErrors || {});


exports.QuoteErrors = QuoteErrors;
