"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _containerbox = require('../container-box');
var _styledcomponents = require('styled-components');
var _theme = require('../../theme');
var _icons = require('../../icons');
const SortIcon = (_ref) => {
  let {
    direction
  } = _ref;
  const {
    palette: {
      mode
    }
  } = _styledcomponents.useTheme.call(void 0, );
  const arrowUpColor = direction === "asc" ? _theme.colors[mode].typography.typo1 : _theme.colors[mode].typography.typo5;
  const arrowDownColor = direction === "desc" ? _theme.colors[mode].typography.typo1 : _theme.colors[mode].typography.typo5;
  return /* @__PURE__ */ _react2.default.createElement(_containerbox.ContainerBox, {
    flexDirection: "column",
    alignItems: "center"
  }, /* @__PURE__ */ _react2.default.createElement(_icons.ArrowUpIcon, {
    sx: {
      color: arrowUpColor,
      fontSize: _theme.SPACING.call(void 0, 2.5)
    }
  }), /* @__PURE__ */ _react2.default.createElement(_icons.ArrowUpIcon, {
    sx: {
      color: arrowDownColor,
      fontSize: _theme.SPACING.call(void 0, 2.5),
      transform: "rotate(180deg)"
    }
  }));
};


exports.SortIcon = SortIcon;
