"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _colors = require('../colors');
var _constants = require('../constants');
const buildTooltipVariant = (mode) => ({
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: _colors.colors[mode].background.emphasis,
        border: `1px solid ${_colors.colors[mode].border.border1}`,
        borderRadius: _constants.SPACING.call(void 0, 2),
        padding: _constants.SPACING.call(void 0, 3),
        boxShadow: _colors.colors[mode].dropShadow.dropShadow200,
        color: _colors.colors[mode].typography.typo2
      },
      arrow: {
        color: _colors.colors[mode].background.emphasis,
        "&:before": {
          border: `1px solid ${_colors.colors[mode].border.border1}`
        }
      }
    },
    defaultProps: {
      placement: "top",
      arrow: true
    }
  }
});


exports.buildTooltipVariant = buildTooltipVariant;
