"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function Money2(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 25 25",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M17.9375 21.75H7.9375C4.2875 21.75 2.1875 19.65 2.1875 16V9C2.1875 5.35 4.2875 3.25 7.9375 3.25H17.9375C21.5875 3.25 23.6875 5.35 23.6875 9V16C23.6875 19.65 21.5875 21.75 17.9375 21.75ZM7.9375 4.75C5.0775 4.75 3.6875 6.14 3.6875 9V16C3.6875 18.86 5.0775 20.25 7.9375 20.25H17.9375C20.7975 20.25 22.1875 18.86 22.1875 16V9C22.1875 6.14 20.7975 4.75 17.9375 4.75H7.9375Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M12.9375 16.25C10.8675 16.25 9.1875 14.57 9.1875 12.5C9.1875 10.43 10.8675 8.75 12.9375 8.75C15.0075 8.75 16.6875 10.43 16.6875 12.5C16.6875 14.57 15.0075 16.25 12.9375 16.25ZM12.9375 10.25C11.6975 10.25 10.6875 11.26 10.6875 12.5C10.6875 13.74 11.6975 14.75 12.9375 14.75C14.1775 14.75 15.1875 13.74 15.1875 12.5C15.1875 11.26 14.1775 10.25 12.9375 10.25Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M3.9375 10.25H2.9375C2.5275 10.25 2.1875 9.91 2.1875 9.5C2.1875 9.09 2.5275 8.75 2.9375 8.75H3.9375C6.5175 8.75 7.1875 8.08 7.1875 5.5V4.5C7.1875 4.09 7.5275 3.75 7.9375 3.75C8.3475 3.75 8.6875 4.09 8.6875 4.5V5.5C8.6875 8.92 7.3575 10.25 3.9375 10.25Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M22.9375 10.25H21.9375C18.5175 10.25 17.1875 8.92 17.1875 5.5V4.5C17.1875 4.09 17.5275 3.75 17.9375 3.75C18.3475 3.75 18.6875 4.09 18.6875 4.5V5.5C18.6875 8.08 19.3575 8.75 21.9375 8.75H22.9375C23.3475 8.75 23.6875 9.09 23.6875 9.5C23.6875 9.91 23.3475 10.25 22.9375 10.25Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M7.9375 21.25C7.5275 21.25 7.1875 20.91 7.1875 20.5V19.5C7.1875 16.92 6.5175 16.25 3.9375 16.25H2.9375C2.5275 16.25 2.1875 15.91 2.1875 15.5C2.1875 15.09 2.5275 14.75 2.9375 14.75H3.9375C7.3575 14.75 8.6875 16.08 8.6875 19.5V20.5C8.6875 20.91 8.3475 21.25 7.9375 21.25Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M17.9375 21.25C17.5275 21.25 17.1875 20.91 17.1875 20.5V19.5C17.1875 16.08 18.5175 14.75 21.9375 14.75H22.9375C23.3475 14.75 23.6875 15.09 23.6875 15.5C23.6875 15.91 23.3475 16.25 22.9375 16.25H21.9375C19.3575 16.25 18.6875 16.92 18.6875 19.5V20.5C18.6875 20.91 18.3475 21.25 17.9375 21.25Z"
  }));
}


exports.default = Money2;
