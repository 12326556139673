"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _colors = require('./colors');
const basePallete = {
  common: {
    black: _colors.baseColors.black,
    white: _colors.baseColors.white
  },
  action: {
    active: "#fff",
    hover: "rgba(255, 255, 255, 0.08)",
    hoverOpacity: 0.08,
    selected: "rgba(255, 255, 255, 0.16)",
    selectedOpacity: 0.16,
    disabled: "rgba(255, 255, 255, 0.3)",
    disabledBackground: "rgba(255, 255, 255, 0.12)",
    disabledOpacity: 0.38,
    focus: "rgba(255, 255, 255, 0.12)",
    focusOpacity: 0.12,
    activatedOpacity: 0.24
  },
  grey: {
    50: _colors.baseColors.greyscale.greyscale0,
    100: _colors.baseColors.greyscale.greyscale1,
    200: _colors.baseColors.greyscale.greyscale2,
    300: _colors.baseColors.greyscale.greyscale3,
    400: _colors.baseColors.greyscale.greyscale4,
    500: _colors.baseColors.greyscale.greyscale5,
    600: _colors.baseColors.greyscale.greyscale6,
    700: _colors.baseColors.greyscale.greyscale7,
    800: _colors.baseColors.greyscale.greyscale8,
    900: _colors.baseColors.greyscale.greyscale9,
    A100: "#f5f5f5",
    A200: "#eeeeee",
    A400: "#bdbdbd",
    A700: "#616161"
  },
  contrastThreshold: 3,
  tonalOffset: 0.2,
  divider: "rgba(255, 255, 255, 0.12)",
  typo1: _colors.colors.dark.typography.typo1,
  typo2: _colors.colors.dark.typography.typo2,
  typo3: _colors.colors.dark.typography.typo3,
  typo4: _colors.colors.dark.typography.typo4,
  typo5: _colors.colors.dark.typography.typo5
};
const darkModePallete = {
  ...basePallete,
  mode: "dark",
  secondary: {
    main: _colors.colors.dark.accentPrimary,
    dark: _colors.colors.dark.violet.violet700,
    contrastText: _colors.colors.dark.violet.violet900
  },
  primary: {
    main: _colors.colors.dark.aqua.aqua500,
    dark: _colors.colors.dark.aqua.aqua800,
    contrastText: _colors.colors.dark.aqua.aqua100
  },
  error: {
    main: _colors.colors.dark.semantic.error.primary,
    dark: _colors.colors.dark.semantic.error.darker,
    light: _colors.colors.dark.semantic.error.light
  },
  warning: {
    main: _colors.colors.dark.semantic.warning.primary,
    dark: _colors.colors.dark.semantic.warning.darker,
    light: _colors.colors.dark.semantic.warning.light
  },
  success: {
    main: _colors.colors.dark.semantic.success.primary,
    dark: _colors.colors.dark.semantic.success.darker,
    light: _colors.colors.dark.semantic.success.light
  },
  typo1: _colors.colors.dark.typography.typo1,
  typo2: _colors.colors.dark.typography.typo2,
  typo3: _colors.colors.dark.typography.typo3,
  typo4: _colors.colors.dark.typography.typo4,
  typo5: _colors.colors.dark.typography.typo5,
  info: {
    //default
    main: _colors.colors.dark.typography.typo2,
    light: "#4fc3f7",
    dark: "#0288d1",
    contrastText: "rgba(0, 0, 0, 0.87)"
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // transparent: {
  //   main: 'rgba(255, 255, 255, 0.1)',
  // },
  // default: {
  //   main: '#292929',
  // },
  // migrate: {
  //   main: '#00A3F5',
  // },
  // pending: {
  //   main: '#F9DCF4',
  // },
  background: {
    paper: _colors.colors.dark.violet.violet200,
    default: _colors.colors.dark.violet.violet100
  },
  text: {
    primary: _colors.colors.dark.typography.typo2,
    secondary: _colors.colors.dark.typography.typo3,
    disabled: _colors.colors.dark.typography.typo4
  },
  gradient: {
    main: `linear-gradient(0deg, ${_colors.colors.dark.background.emphasis} -1.99%, ${_colors.baseColors.violet.violet500} 100%)`,
    earnWizard: `linear-gradient(90deg, #270A50 0%, #16062D 100%)`,
    newsBanner: `linear-gradient(286deg, #7A1BFF 1.4%, #0EECC1 113.11%)`
  }
};
const lightModePallete = {
  ...basePallete,
  mode: "light",
  primary: {
    main: _colors.colors.light.accentPrimary,
    dark: _colors.colors.light.violet.violet300,
    contrastText: _colors.colors.light.violet.violet100
  },
  secondary: {
    main: _colors.colors.light.aqua.aqua500,
    dark: _colors.colors.light.aqua.aqua200,
    contrastText: _colors.colors.light.aqua.aqua900
  },
  error: {
    main: _colors.colors.light.semantic.error.primary,
    dark: _colors.colors.light.semantic.error.darker,
    light: _colors.colors.light.semantic.error.light
  },
  warning: {
    main: _colors.colors.light.semantic.warning.primary,
    dark: _colors.colors.light.semantic.warning.darker,
    light: _colors.colors.light.semantic.warning.light
  },
  success: {
    main: _colors.colors.light.semantic.success.primary,
    dark: _colors.colors.light.semantic.success.darker,
    light: _colors.colors.light.semantic.success.light
  },
  typo1: _colors.colors.light.typography.typo1,
  typo2: _colors.colors.light.typography.typo2,
  typo3: _colors.colors.light.typography.typo3,
  typo4: _colors.colors.light.typography.typo4,
  typo5: _colors.colors.light.typography.typo5,
  info: {
    //default
    main: _colors.colors.light.typography.typo2,
    light: "#4fc3f7",
    dark: "#0288d1",
    contrastText: "rgba(0, 0, 0, 0.87)"
  },
  background: {
    paper: _colors.colors.light.background.secondary,
    default: _colors.colors.light.background.primary
  },
  text: {
    primary: _colors.colors.light.typography.typo2,
    secondary: _colors.colors.light.typography.typo3,
    disabled: _colors.colors.light.typography.typo4
  },
  gradient: {
    main: `linear-gradient(0deg, ${_colors.colors.light.background.secondary} -1.99%, ${_colors.baseColors.violet.violet500} 100%)`,
    earnWizard: `linear-gradient(90deg, #EADBFF 0%, #D3B4FFCC 100%)`,
    newsBanner: `linear-gradient(286deg, #7A1BFF 1.4%, #0EECC1 113.11%)`
  }
};



exports.darkModePallete = darkModePallete; exports.lightModePallete = lightModePallete;
