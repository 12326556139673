"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function DiagramIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 21 21",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    id: "Vector",
    d: "M19.2077 19.8268H5.04102C3.31602 19.8268 1.91602 18.4268 1.91602 16.7018V2.53516C1.91602 2.19349 2.19935 1.91016 2.54102 1.91016C2.88268 1.91016 3.16602 2.19349 3.16602 2.53516V16.7018C3.16602 17.7352 4.00768 18.5768 5.04102 18.5768H19.2077C19.5493 18.5768 19.8327 18.8602 19.8327 19.2018C19.8327 19.5435 19.5493 19.8268 19.2077 19.8268Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    id: "Vector_2",
    d: "M5.04087 15.6574C4.8992 15.6574 4.7492 15.6074 4.63253 15.5074C4.3742 15.2824 4.34087 14.8907 4.56587 14.6241L8.39087 10.1574C8.80753 9.67407 9.40753 9.38241 10.0409 9.35741C10.6742 9.34074 11.2992 9.57406 11.7492 10.0241L12.5409 10.8157C12.7492 11.0241 13.0242 11.1241 13.3159 11.1241C13.6075 11.1157 13.8742 10.9824 14.0659 10.7574L17.8909 6.29075C18.1159 6.03241 18.5075 5.99906 18.7742 6.22406C19.0325 6.44906 19.0659 6.84073 18.8409 7.1074L15.0159 11.5741C14.5992 12.0574 13.9992 12.3491 13.3659 12.3741C12.7325 12.3907 12.1075 12.1574 11.6575 11.7074L10.8742 10.9157C10.6659 10.7074 10.3909 10.5991 10.0992 10.6074C9.80753 10.6157 9.54087 10.7491 9.3492 10.9741L5.5242 15.4407C5.39087 15.5824 5.21587 15.6574 5.04087 15.6574Z"
  }));
}


exports.default = DiagramIcon;
