"use strict";Object.defineProperty(exports, "__esModule", {value: true});var WalletType = /* @__PURE__ */ ((WalletType2) => {
  WalletType2["embedded"] = "embedded";
  WalletType2["external"] = "external";
  return WalletType2;
})(WalletType || {});
var WalletStatus = /* @__PURE__ */ ((WalletStatus2) => {
  WalletStatus2["connected"] = "connected";
  WalletStatus2["disconnected"] = "disconnected";
  return WalletStatus2;
})(WalletStatus || {});
var UserStatus = /* @__PURE__ */ ((UserStatus2) => {
  UserStatus2["loggedIn"] = "loggedIn";
  UserStatus2["notLogged"] = "notLogged";
  return UserStatus2;
})(UserStatus || {});




exports.UserStatus = UserStatus; exports.WalletStatus = WalletStatus; exports.WalletType = WalletType;
