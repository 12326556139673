"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../../components/svgicon');
var _styledcomponents = require('styled-components');
var _theme = require('../../theme');
function AvalancheLogoMinimalistic(_ref) {
  let {
    size,
    height,
    width,
    ...props
  } = _ref;
  const {
    palette: {
      mode
    }
  } = _styledcomponents.useTheme.call(void 0, );
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 15 13",
    fill: "none",
    style: {
      fontSize: size,
      height,
      width
    }
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M4.01248 12.625H1.66041C1.16549 12.625 0.920485 12.625 0.772781 12.5331C0.693921 12.4842 0.627813 12.4158 0.580275 12.334C0.533626 12.2538 0.506251 12.163 0.500472 12.0693C0.491372 11.8943 0.613876 11.685 0.858884 11.2599L6.66907 1.16232C6.91827 0.733533 7.04498 0.519141 7.20318 0.440385C7.28752 0.397365 7.38018 0.375 7.47409 0.375C7.568 0.375 7.66066 0.397365 7.745 0.440385C7.90251 0.519141 8.02501 0.733533 8.27002 1.16232L9.46915 3.21873L9.47335 3.22748C9.67006 3.51917 9.82617 3.83493 9.93677 4.16818C10.0033 4.4307 10.0033 4.7151 9.93677 4.982C9.82595 5.31962 9.66994 5.63925 9.47335 5.93145L6.41986 11.2519L6.41076 11.2694C6.25465 11.5866 6.05375 11.8819 5.81574 12.1444C5.60815 12.3377 5.36148 12.4797 5.09402 12.5601C4.84481 12.625 4.5683 12.625 4.01178 12.625M9.95917 12.625H13.3277C13.8317 12.625 14.0802 12.625 14.2293 12.5287C14.3078 12.4799 14.3737 12.4118 14.4211 12.3304C14.4673 12.25 14.4942 12.1592 14.4995 12.0657C14.5086 11.895 14.3903 11.6938 14.1544 11.2949L14.1278 11.2511L12.44 8.40716L12.4225 8.37289C12.1852 7.97911 12.0627 7.77784 11.9101 7.69909C11.8266 7.65557 11.7344 7.63292 11.641 7.63292C11.5475 7.63292 11.4554 7.65557 11.3718 7.69909C11.215 7.77784 11.0918 7.98786 10.8468 8.40789L9.15764 11.2562V11.265C8.90844 11.6836 8.78663 11.895 8.79503 12.0657C8.80038 12.1598 8.82752 12.2511 8.87414 12.3319C8.92121 12.4144 8.98709 12.4835 9.06594 12.5331C9.21085 12.625 9.46005 12.625 9.95917 12.625Z",
    fill: _theme.colors[mode].violet.violet200
  }));
}


exports.default = AvalancheLogoMinimalistic;
